/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Analytics
// ====================================================

export interface Analytics_analytics_activeMembers {
  __typename: "ActiveMembersAnalytics";
  month6: number;
}

export interface Analytics_analytics_cumulativePayouts {
  __typename: "CumulativePayoutsAnalytics";
  month0: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
}

export interface Analytics_analytics_payments {
  __typename: "PaymentsAnalytics";
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
}

export interface Analytics_analytics_subscriptions_planMemberCounts {
  __typename: "PlanMemberCount";
  plan: string;
  memberCount: number;
}

export interface Analytics_analytics_subscriptions {
  __typename: "SubscriptionsAnalytics";
  autoRenewalRate: number;
  totalMemeberActive: number;
  planMemberCounts: Analytics_analytics_subscriptions_planMemberCounts[];
}

export interface Analytics_analytics {
  __typename: "Analytics";
  activeMembers: Analytics_analytics_activeMembers;
  cumulativePayouts: Analytics_analytics_cumulativePayouts;
  payments: Analytics_analytics_payments;
  subscriptions: Analytics_analytics_subscriptions;
}

export interface Analytics {
  analytics: Analytics_analytics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clearentMerchantSetting
// ====================================================

export interface clearentMerchantSetting_clearentMerchantSetting_merchantAccount_phones {
  __typename: "MerchantPhone";
  phoneTypeCodeID: number;
  areaCode: string;
  phoneNumber: string;
  extension: string | null;
}

export interface clearentMerchantSetting_clearentMerchantSetting_merchantAccount {
  __typename: "MerchantAccount";
  businessID: string;
  dbaName: string;
  merchantNumber: string;
  emailAddress: string;
  phones: clearentMerchantSetting_clearentMerchantSetting_merchantAccount_phones[] | null;
}

export interface clearentMerchantSetting_clearentMerchantSetting {
  __typename: "MerchantSetting";
  connected: boolean;
  defaultEmail: string | null;
  defaultName: string | null;
  merchantNumber: string | null;
  merchantAccount: clearentMerchantSetting_clearentMerchantSetting_merchantAccount | null;
}

export interface clearentMerchantSetting {
  clearentMerchantSetting: clearentMerchantSetting_clearentMerchantSetting;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: onboardingComplete
// ====================================================

export interface onboardingComplete {
  onboardingComplete: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentCreateMerchant
// ====================================================

export interface clearentCreateMerchant_clearentCreateMerchant {
  __typename: "MerchantAccount";
  businessID: string;
  dbaName: string;
  emailAddress: string;
  merchantNumber: string;
}

export interface clearentCreateMerchant {
  clearentCreateMerchant: clearentCreateMerchant_clearentCreateMerchant;
}

export interface clearentCreateMerchantVariables {
  dbaName: string;
  email: string;
  phone_area: string;
  phone_number: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentUpdateMerchant
// ====================================================

export interface clearentUpdateMerchant_clearentUpdateMerchant {
  __typename: "MerchantAccount";
  businessID: string;
  dbaName: string;
  emailAddress: string;
  merchantNumber: string;
}

export interface clearentUpdateMerchant {
  clearentUpdateMerchant: clearentUpdateMerchant_clearentUpdateMerchant;
}

export interface clearentUpdateMerchantVariables {
  dbaName: string;
  email: string;
  phone_area: string;
  phone_number: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentUpdateAddress
// ====================================================

export interface clearentUpdateAddress_clearentUpdateAddress {
  __typename: "MerchantAddress";
  city: string | null;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  stateCode: string | null;
  zip: string | null;
  addressType: number | null;
}

export interface clearentUpdateAddress {
  clearentUpdateAddress: clearentUpdateAddress_clearentUpdateAddress;
}

export interface clearentUpdateAddressVariables {
  addressType: number;
  city: string;
  countryCode: number;
  line1: string;
  line2?: string | null;
  line3?: string | null;
  stateCode: string;
  zip?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clearentAddress
// ====================================================

export interface clearentAddress_clearentAddress {
  __typename: "MerchantAddress";
  city: string | null;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  stateCode: string | null;
  zip: string | null;
}

export interface clearentAddress {
  clearentAddress: clearentAddress_clearentAddress;
}

export interface clearentAddressVariables {
  addressType: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentUpdateTaxPayer
// ====================================================

export interface clearentUpdateTaxPayer_clearentUpdateTaxPayer {
  __typename: "MerchantTaxPayerInfo";
  businessLegalName: string;
  legalFirstName: string | null;
  legalLastName: string | null;
  stateIncorporatedCode: string;
  tin: string;
  tinTypeID: number;
}

export interface clearentUpdateTaxPayer {
  clearentUpdateTaxPayer: clearentUpdateTaxPayer_clearentUpdateTaxPayer;
}

export interface clearentUpdateTaxPayerVariables {
  businessLegalName: string;
  legalFirstName?: string | null;
  legalLastName?: string | null;
  stateIncorporatedCode: string;
  tin: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clearentTaxPayer
// ====================================================

export interface clearentTaxPayer_clearentTaxPayer {
  __typename: "MerchantTaxPayerInfo";
  businessLegalName: string;
  legalFirstName: string | null;
  legalLastName: string | null;
  stateIncorporatedCode: string;
  tin: string;
  tinTypeID: number;
}

export interface clearentTaxPayer {
  clearentTaxPayer: clearentTaxPayer_clearentTaxPayer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentAddBusinessContact
// ====================================================

export interface clearentAddBusinessContact_clearentAddBusinessContact {
  __typename: "MerchantBusinessContact";
  businessContactId: number | null;
  emailAddress: string | null;
  ownershipAmount: number;
  title: string;
}

export interface clearentAddBusinessContact {
  clearentAddBusinessContact: clearentAddBusinessContact_clearentAddBusinessContact;
}

export interface clearentAddBusinessContactVariables {
  contact: MerchantContactInput;
  emailAddress?: string | null;
  ownershipAmount: number;
  phoneNumbers?: MerchantPhoneInput[] | null;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentDeleteBusinessContact
// ====================================================

export interface clearentDeleteBusinessContact {
  clearentDeleteBusinessContact: boolean;
}

export interface clearentDeleteBusinessContactVariables {
  contactId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clearentBusinessContacts
// ====================================================

export interface clearentBusinessContacts_clearentBusinessContacts {
  __typename: "MerchantBusinessContact";
  businessContactId: number | null;
  emailAddress: string | null;
  ownershipAmount: number;
  title: string;
}

export interface clearentBusinessContacts {
  clearentBusinessContacts: clearentBusinessContacts_clearentBusinessContacts[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentAddBank
// ====================================================

export interface clearentAddBank_clearentAddBank {
  __typename: "ClearentBankAccount";
  aba: string;
  accountHolderFirstName: string;
  accountHolderLastName: string;
  accountNumber: string;
  bankAccountID: number;
  bankAccountNameTypeID: number;
  bankAccountTypeID: number;
  bankName: string;
  hasChargebacks: boolean;
  hasFees: boolean;
  hasFunds: boolean;
  isNameSameAsLegalOrDBAName: boolean;
  nameOnAccount: string;
  voidedCheckDocumentID: string;
}

export interface clearentAddBank {
  clearentAddBank: clearentAddBank_clearentAddBank;
}

export interface clearentAddBankVariables {
  aba: string;
  accountHolderFirstName: string;
  accountHolderLastName: string;
  accountNumber: string;
  bankAccountNameTypeID: number;
  bankAccountTypeID: number;
  bankName: string;
  nameOnAccount: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentUpdateBank
// ====================================================

export interface clearentUpdateBank_clearentUpdateBank {
  __typename: "ClearentBankAccount";
  aba: string;
  accountHolderFirstName: string;
  accountHolderLastName: string;
  accountNumber: string;
  bankAccountID: number;
  bankAccountNameTypeID: number;
  bankAccountTypeID: number;
  bankName: string;
  hasChargebacks: boolean;
  hasFees: boolean;
  hasFunds: boolean;
  isNameSameAsLegalOrDBAName: boolean;
  nameOnAccount: string;
  voidedCheckDocumentID: string;
}

export interface clearentUpdateBank {
  clearentUpdateBank: clearentUpdateBank_clearentUpdateBank;
}

export interface clearentUpdateBankVariables {
  accountHolderFirstName?: string | null;
  accountHolderLastName?: string | null;
  bankAccountID: number;
  bankName?: string | null;
  nameOnAccount?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clearentDeleteBank
// ====================================================

export interface clearentDeleteBank {
  clearentDeleteBank: boolean;
}

export interface clearentDeleteBankVariables {
  bankAccountID: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clearentBanks
// ====================================================

export interface clearentBanks_clearentBanks {
  __typename: "ClearentBankAccount";
  aba: string;
  accountHolderFirstName: string;
  accountHolderLastName: string;
  accountNumber: string;
  bankAccountID: number;
  bankAccountNameTypeID: number;
  bankAccountTypeID: number;
  bankName: string;
  hasChargebacks: boolean;
  hasFees: boolean;
  hasFunds: boolean;
  isNameSameAsLegalOrDBAName: boolean;
  nameOnAccount: string;
  voidedCheckDocumentID: string;
}

export interface clearentBanks {
  clearentBanks: clearentBanks_clearentBanks[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Dashboard
// ====================================================

export interface Dashboard_analytics_activeMembers {
  __typename: "ActiveMembersAnalytics";
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
}

export interface Dashboard_analytics_cumulativePayouts {
  __typename: "CumulativePayoutsAnalytics";
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
}

export interface Dashboard_analytics_subscriptions {
  __typename: "SubscriptionsAnalytics";
  totalMemeberActive: number;
}

export interface Dashboard_analytics {
  __typename: "Analytics";
  activeMembers: Dashboard_analytics_activeMembers;
  cumulativePayouts: Dashboard_analytics_cumulativePayouts;
  subscriptions: Dashboard_analytics_subscriptions;
}

export interface Dashboard {
  analytics: Dashboard_analytics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideUserWelcome
// ====================================================

export interface HideUserWelcome_updateUser {
  __typename: "User";
  id: string;
  showWelcome: boolean;
}

export interface HideUserWelcome {
  updateUser: HideUserWelcome_updateUser;
}

export interface HideUserWelcomeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertDentist
// ====================================================

export interface UpsertDentist_upsertDentist {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface UpsertDentist {
  upsertDentist: UpsertDentist_upsertDentist;
}

export interface UpsertDentistVariables {
  id?: string | null;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDentist
// ====================================================

export interface DeleteDentist_deleteDentist {
  __typename: "Dentist";
  id: string;
}

export interface DeleteDentist {
  deleteDentist: DeleteDentist_deleteDentist;
}

export interface DeleteDentistVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDentists
// ====================================================

export interface GetDentists_dentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface GetDentists {
  dentists: GetDentists_dentists[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFaqs
// ====================================================

export interface GetFaqs_FAQs {
  __typename: "FAQ";
  id: string;
  index: number;
  question: string;
  answer: string;
  status: FAQStatus;
}

export interface GetFaqs {
  FAQs: GetFaqs_FAQs[];
}

export interface GetFaqsVariables {
  status?: FAQStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FAQSearch
// ====================================================

export interface FAQSearch_FAQSearch_edges_node {
  __typename: "FAQ";
  id: string;
  index: number;
  question: string;
  answer: string;
  status: FAQStatus;
}

export interface FAQSearch_FAQSearch_edges {
  __typename: "FAQEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: FAQSearch_FAQSearch_edges_node;
}

export interface FAQSearch_FAQSearch_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface FAQSearch_FAQSearch {
  __typename: "FAQConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (FAQSearch_FAQSearch_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: FAQSearch_FAQSearch_pageInfo;
}

export interface FAQSearch {
  FAQSearch: FAQSearch_FAQSearch;
}

export interface FAQSearchVariables {
  search: string;
  indexOrder: number;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: singleFAQ
// ====================================================

export interface singleFAQ_singleFAQ {
  __typename: "FAQ";
  id: string;
  index: number;
  question: string;
  answer: string;
  status: FAQStatus;
}

export interface singleFAQ {
  singleFAQ: singleFAQ_singleFAQ | null;
}

export interface singleFAQVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addFAQ
// ====================================================

export interface addFAQ_addFAQ {
  __typename: "FAQ";
  id: string;
  index: number;
  question: string;
  answer: string;
  status: FAQStatus;
}

export interface addFAQ {
  addFAQ: addFAQ_addFAQ;
}

export interface addFAQVariables {
  index: number;
  question: string;
  answer: string;
  status: FAQStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFAQ
// ====================================================

export interface updateFAQ_updateFAQ {
  __typename: "FAQ";
  id: string;
  index: number;
  question: string;
  answer: string;
  status: FAQStatus;
}

export interface updateFAQ {
  updateFAQ: updateFAQ_updateFAQ;
}

export interface updateFAQVariables {
  id: string;
  index: number;
  question: string;
  answer: string;
  status: FAQStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFAQ
// ====================================================

export interface deleteFAQ_deleteFAQ {
  __typename: "FAQ";
  id: string;
}

export interface deleteFAQ {
  deleteFAQ: deleteFAQ_deleteFAQ;
}

export interface deleteFAQVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: flyers
// ====================================================

export interface flyers_flyers {
  __typename: "Flyer";
  id: string;
  index: number;
  title: string;
  url: string;
  status: FlyerStatus;
}

export interface flyers {
  flyers: flyers_flyers[];
}

export interface flyersVariables {
  status?: FlyerStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: flyerSearch
// ====================================================

export interface flyerSearch_flyerSearch_edges_node {
  __typename: "Flyer";
  id: string;
  index: number;
  title: string;
  url: string;
  status: FlyerStatus;
}

export interface flyerSearch_flyerSearch_edges {
  __typename: "FlyerEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: flyerSearch_flyerSearch_edges_node;
}

export interface flyerSearch_flyerSearch_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface flyerSearch_flyerSearch {
  __typename: "FlyerConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (flyerSearch_flyerSearch_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: flyerSearch_flyerSearch_pageInfo;
}

export interface flyerSearch {
  flyerSearch: flyerSearch_flyerSearch;
}

export interface flyerSearchVariables {
  search: string;
  indexOrder: number;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: singleFlyer
// ====================================================

export interface singleFlyer_singleFlyer {
  __typename: "Flyer";
  id: string;
  index: number;
  title: string;
  url: string;
  status: FlyerStatus;
}

export interface singleFlyer {
  singleFlyer: singleFlyer_singleFlyer | null;
}

export interface singleFlyerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addFlyer
// ====================================================

export interface addFlyer_addFlyer {
  __typename: "Flyer";
  id: string;
  index: number;
  title: string;
  url: string;
  status: FlyerStatus;
}

export interface addFlyer {
  addFlyer: addFlyer_addFlyer;
}

export interface addFlyerVariables {
  index: number;
  title: string;
  url: string;
  status: FlyerStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFlyer
// ====================================================

export interface updateFlyer_updateFlyer {
  __typename: "Flyer";
  id: string;
  index: number;
  title: string;
  url: string;
  status: FlyerStatus;
}

export interface updateFlyer {
  updateFlyer: updateFlyer_updateFlyer;
}

export interface updateFlyerVariables {
  id: string;
  index: number;
  title: string;
  url: string;
  status: FlyerStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFlyer
// ====================================================

export interface deleteFlyer_deleteFlyer {
  __typename: "Flyer";
  id: string;
}

export interface deleteFlyer {
  deleteFlyer: deleteFlyer_deleteFlyer;
}

export interface deleteFlyerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Global
// ====================================================

export interface Global_meUser_google {
  __typename: "GoogleUserFields";
  email: string;
  role: UserRole;
}

export interface Global_meUser_accounts {
  __typename: "Account";
  id: string;
  name: string;
}

export interface Global_meUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  showWelcome: boolean;
  google: Global_meUser_google;
  accounts: Global_meUser_accounts[];
}

export interface Global_account {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
  acceptPayments: boolean;
  clearentPaymentKey: string | null;
  merchantNumber: string | null;
  type: AccountType;
  status: AccountStatus;
}

export interface Global {
  meUser: Global_meUser | null;
  account: Global_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCurrentAccountId
// ====================================================

export interface SetCurrentAccountId {
  setCurrentAccountId: string;
}

export interface SetCurrentAccountIdVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InvoicesTable
// ====================================================

export interface InvoicesTable_ContactInvoice_contact {
  __typename: "Contact";
  secure: string;
  code: string;
}

export interface InvoicesTable_ContactInvoice {
  __typename: "ContactInvoice";
  id: string;
  invoiceDate: any;
  status: invoiceStatus;
  invoiceSequence: number;
  rangeEnd: any | null;
  amount: number;
  rangeStart: any | null;
  contact: InvoicesTable_ContactInvoice_contact;
}

export interface InvoicesTable {
  ContactInvoice: InvoicesTable_ContactInvoice[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactInvoicePagination
// ====================================================

export interface ContactInvoicePagination_ContactInvoiceConnection_edges_node_contact {
  __typename: "Contact";
  secure: string;
  code: string;
}

export interface ContactInvoicePagination_ContactInvoiceConnection_edges_node {
  __typename: "ContactInvoice";
  id: string;
  invoiceDate: any;
  status: invoiceStatus;
  invoiceSequence: number;
  rangeEnd: any | null;
  amount: number;
  rangeStart: any | null;
  contact: ContactInvoicePagination_ContactInvoiceConnection_edges_node_contact;
}

export interface ContactInvoicePagination_ContactInvoiceConnection_edges {
  __typename: "ContactInvoiceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: ContactInvoicePagination_ContactInvoiceConnection_edges_node;
}

export interface ContactInvoicePagination_ContactInvoiceConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface ContactInvoicePagination_ContactInvoiceConnection {
  __typename: "ContactInvoiceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (ContactInvoicePagination_ContactInvoiceConnection_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: ContactInvoicePagination_ContactInvoiceConnection_pageInfo;
}

export interface ContactInvoicePagination {
  ContactInvoiceConnection: ContactInvoicePagination_ContactInvoiceConnection;
}

export interface ContactInvoicePaginationVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: practiceServiceInvoiceEventByFilter
// ====================================================

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_subscription_plan {
  __typename: "Plan";
  name: string;
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_subscription {
  __typename: "ServicePlanSubscription";
  plan: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_subscription_plan | null;
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_account_offices {
  __typename: "Office";
  name: string;
  email: string | null;
  address1: string;
  address2: string | null;
  phone: string;
  city: string;
  state: State;
  zip: string;
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_account {
  __typename: "Account";
  code: string;
  name: string;
  offices: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_account_offices[];
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_subscriberAccount_offices {
  __typename: "Office";
  name: string;
  email: string | null;
  address1: string;
  address2: string | null;
  phone: string;
  city: string;
  state: State;
  zip: string;
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_subscriberAccount {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
  offices: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_subscriberAccount_offices[];
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node {
  __typename: "ServiceInvoice";
  id: string;
  type: paymentType;
  invoiceDate: any;
  status: serviceInvoiceStatus;
  invoiceSequence: number;
  rangeEnd: any | null;
  amount: number;
  rangeStart: any | null;
  subscription: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_subscription | null;
  paymentDueDate: any | null;
  account: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_account;
  subscriberAccount: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node_subscriberAccount | null;
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges {
  __typename: "ServiceInvoiceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges_node;
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter {
  __typename: "ServiceInvoiceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter_pageInfo;
}

export interface practiceServiceInvoiceEventByFilter {
  practiceServiceInvoiceEventByFilter: practiceServiceInvoiceEventByFilter_practiceServiceInvoiceEventByFilter;
}

export interface practiceServiceInvoiceEventByFilterVariables {
  invoiceSequence?: string | null;
  status?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CommunicationTemplate
// ====================================================

export interface CommunicationTemplate_communicationTemplate {
  __typename: "CommunicationTemplate";
  id: string;
  enabled: boolean;
  type: CommunicationTemplateType;
  content: string;
}

export interface CommunicationTemplate {
  communicationTemplate: CommunicationTemplate_communicationTemplate[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertCommunicationTemplate
// ====================================================

export interface UpsertCommunicationTemplate_upsertCommunicationTemplate {
  __typename: "CommunicationTemplate";
  id: string;
  enabled: boolean;
  type: CommunicationTemplateType;
  content: string;
}

export interface UpsertCommunicationTemplate {
  upsertCommunicationTemplate: UpsertCommunicationTemplate_upsertCommunicationTemplate;
}

export interface UpsertCommunicationTemplateVariables {
  id?: string | null;
  enabled: boolean;
  type: CommunicationTemplateType;
  content: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MarketingSite
// ====================================================

export interface MarketingSite_account {
  __typename: "Account";
  id: string;
  siteImage: SiteImage;
  siteColor: SiteColor;
  logoUrl: string | null;
  siteUrl: string | null;
  slug: string | null;
  siteVideoUrl: string | null;
  sitePhone: string;
}

export interface MarketingSite {
  account: MarketingSite_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMarketingSite
// ====================================================

export interface UpdateMarketingSite_updateAccount {
  __typename: "Account";
  id: string;
  siteImage: SiteImage;
  siteColor: SiteColor;
  logoUrl: string | null;
  siteUrl: string | null;
  siteVideoUrl: string | null;
  slug: string | null;
  sitePhone: string;
}

export interface UpdateMarketingSite {
  updateAccount: UpdateMarketingSite_updateAccount;
}

export interface UpdateMarketingSiteVariables {
  siteImage?: SiteImage | null;
  siteColor?: SiteColor | null;
  logoUrl?: string | null;
  siteUrl?: string | null;
  sitePhone?: string | null;
  slug?: string | null;
  siteVideoUrl?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MarketingWidget
// ====================================================

export interface MarketingWidget_widget {
  __typename: "Widget";
  id: string;
  themeColor: string;
  isLeft: boolean;
  show: boolean;
  showMobile: boolean;
  title: string;
  icon: WidgetIcon;
  description: string;
  buttonLabel: string;
  solidButton: boolean;
}

export interface MarketingWidget {
  widget: MarketingWidget_widget | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWidget
// ====================================================

export interface UpdateWidget_updateWidget {
  __typename: "Widget";
  id: string;
  themeColor: string;
  isLeft: boolean;
  show: boolean;
  showMobile: boolean;
  title: string;
  icon: WidgetIcon;
  description: string;
  buttonLabel: string;
  solidButton: boolean;
}

export interface UpdateWidget {
  updateWidget: UpdateWidget_updateWidget;
}

export interface UpdateWidgetVariables {
  id?: string | null;
  themeColor?: string | null;
  isLeft?: boolean | null;
  show?: boolean | null;
  showMobile?: boolean | null;
  title?: string | null;
  icon?: WidgetIcon | null;
  description?: string | null;
  buttonLabel?: string | null;
  solidButton?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembersTable
// ====================================================

export interface MembersTable_membersConnection_edges_node_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface MembersTable_membersConnection_edges_node_subscriptions {
  __typename: "ContactPlanSubscription";
  startDate: any;
  lastPaymentDate: any | null;
  endDate: any;
  isEnabled: boolean;
  isCancelled: boolean;
  autoRenewal: boolean;
  status: PlanSubscriptionStatus;
}

export interface MembersTable_membersConnection_edges_node {
  __typename: "Contact";
  id: string;
  secure: string;
  plan: MembersTable_membersConnection_edges_node_plan | null;
  subscriptions: MembersTable_membersConnection_edges_node_subscriptions[] | null;
  dependentCount: number;
}

export interface MembersTable_membersConnection_edges {
  __typename: "ContactEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: MembersTable_membersConnection_edges_node;
}

export interface MembersTable_membersConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface MembersTable_membersConnection {
  __typename: "ContactConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (MembersTable_membersConnection_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: MembersTable_membersConnection_pageInfo;
}

export interface MembersTable {
  membersConnection: MembersTable_membersConnection;
}

export interface MembersTableVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembersSearch
// ====================================================

export interface MembersSearch_membersConnectionSearch_edges_node_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface MembersSearch_membersConnectionSearch_edges_node_subscriptions {
  __typename: "ContactPlanSubscription";
  startDate: any;
  lastPaymentDate: any | null;
  endDate: any;
  isEnabled: boolean;
  isCancelled: boolean;
  autoRenewal: boolean;
  status: PlanSubscriptionStatus;
}

export interface MembersSearch_membersConnectionSearch_edges_node {
  __typename: "Contact";
  id: string;
  secure: string;
  plan: MembersSearch_membersConnectionSearch_edges_node_plan | null;
  subscriptions: MembersSearch_membersConnectionSearch_edges_node_subscriptions[] | null;
  dependentCount: number;
}

export interface MembersSearch_membersConnectionSearch_edges {
  __typename: "ContactEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: MembersSearch_membersConnectionSearch_edges_node;
}

export interface MembersSearch_membersConnectionSearch_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface MembersSearch_membersConnectionSearch {
  __typename: "ContactConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (MembersSearch_membersConnectionSearch_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: MembersSearch_membersConnectionSearch_pageInfo;
}

export interface MembersSearch {
  membersConnectionSearch: MembersSearch_membersConnectionSearch;
}

export interface MembersSearchVariables {
  search: string;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertOffice
// ====================================================

export interface UpsertOffice_upsertOffice {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface UpsertOffice {
  upsertOffice: UpsertOffice_upsertOffice;
}

export interface UpsertOfficeVariables {
  id?: string | null;
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOffice
// ====================================================

export interface DeleteOffice_deleteOffice {
  __typename: "Office";
  id: string;
}

export interface DeleteOffice {
  deleteOffice: DeleteOffice_deleteOffice;
}

export interface DeleteOfficeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOffices
// ====================================================

export interface GetOffices_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface GetOffices {
  offices: GetOffices_offices[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingDashboardTable
// ====================================================

export interface OnboardingDashboardTable_OnboardingDashboardEvents_edges_node_account {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
}

export interface OnboardingDashboardTable_OnboardingDashboardEvents_edges_node_plan {
  __typename: "Plan";
  name: string;
  status: PlanStatus;
}

export interface OnboardingDashboardTable_OnboardingDashboardEvents_edges_node_subscription {
  __typename: "ServicePlanSubscription";
  acceptDate: any | null;
  createdAt: any;
  startDate: any | null;
  endDate: any | null;
  fee: number;
}

export interface OnboardingDashboardTable_OnboardingDashboardEvents_edges_node {
  __typename: "OnboardingDashboard";
  id: string;
  accountId: string;
  account: OnboardingDashboardTable_OnboardingDashboardEvents_edges_node_account;
  datePracticeAdded: any | null;
  marketingMaterialApproved: any | null;
  patientPlansComplete: any | null;
  planId: string | null;
  plan: OnboardingDashboardTable_OnboardingDashboardEvents_edges_node_plan | null;
  practiceActive: any | null;
  practiceClosed: any | null;
  practiceFeeAnalysisComplete: any | null;
  practiceLive: any | null;
  practiceName: string | null;
  practiceProgramApproved: any | null;
  processingComplete: any | null;
  registrationComplete: any | null;
  serviceAgreementComplete: any | null;
  serviceAgreementPending: any | null;
  softwareSetupGuideSent: any | null;
  subscriptionId: string | null;
  onboardType: OnboardingType;
  subscription: OnboardingDashboardTable_OnboardingDashboardEvents_edges_node_subscription | null;
}

export interface OnboardingDashboardTable_OnboardingDashboardEvents_edges {
  __typename: "OnboardingDashboardEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: OnboardingDashboardTable_OnboardingDashboardEvents_edges_node;
}

export interface OnboardingDashboardTable_OnboardingDashboardEvents_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface OnboardingDashboardTable_OnboardingDashboardEvents {
  __typename: "OnboardingDashboardConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (OnboardingDashboardTable_OnboardingDashboardEvents_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: OnboardingDashboardTable_OnboardingDashboardEvents_pageInfo;
}

export interface OnboardingDashboardTable {
  OnboardingDashboardEvents: OnboardingDashboardTable_OnboardingDashboardEvents;
}

export interface OnboardingDashboardTableVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnbordingDashbordTableSearchByName
// ====================================================

export interface OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges_node_account {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
}

export interface OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges_node_plan {
  __typename: "Plan";
  name: string;
}

export interface OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges_node_subscription {
  __typename: "ServicePlanSubscription";
  acceptDate: any | null;
  createdAt: any;
}

export interface OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges_node {
  __typename: "OnboardingDashboard";
  id: string;
  accountId: string;
  account: OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges_node_account;
  datePracticeAdded: any | null;
  marketingMaterialApproved: any | null;
  patientPlansComplete: any | null;
  planId: string | null;
  plan: OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges_node_plan | null;
  practiceActive: any | null;
  practiceClosed: any | null;
  practiceFeeAnalysisComplete: any | null;
  practiceLive: any | null;
  practiceName: string | null;
  practiceProgramApproved: any | null;
  processingComplete: any | null;
  registrationComplete: any | null;
  serviceAgreementComplete: any | null;
  serviceAgreementPending: any | null;
  softwareSetupGuideSent: any | null;
  subscriptionId: string | null;
  onboardType: OnboardingType;
  subscription: OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges_node_subscription | null;
}

export interface OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges {
  __typename: "OnboardingDashboardEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges_node;
}

export interface OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName {
  __typename: "OnboardingDashboardConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName_pageInfo;
}

export interface OnbordingDashbordTableSearchByName {
  OnbordingDashbordTableSearchByName: OnbordingDashbordTableSearchByName_OnbordingDashbordTableSearchByName;
}

export interface OnbordingDashbordTableSearchByNameVariables {
  search: string;
  orderByCol: string;
  order: string;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOnboardingDashboard
// ====================================================

export interface getOnboardingDashboard_getOnboardingDashboard_account {
  __typename: "Account";
  name: string;
  code: string;
  status: AccountStatus;
}

export interface getOnboardingDashboard_getOnboardingDashboard_plan {
  __typename: "Plan";
  name: string;
  status: PlanStatus;
  type: PlanType;
}

export interface getOnboardingDashboard_getOnboardingDashboard_subscription {
  __typename: "ServicePlanSubscription";
  createdAt: any;
  acceptDate: any | null;
  startDate: any | null;
  endDate: any | null;
  fee: number;
  status: PlanSubscriptionStatus;
}

export interface getOnboardingDashboard_getOnboardingDashboard_offices {
  __typename: "Office";
  address1: string;
  address2: string | null;
  city: string;
  zip: string;
  state: State;
  phone: string;
  email: string | null;
}

export interface getOnboardingDashboard_getOnboardingDashboard {
  __typename: "OnboardingDashboard";
  accountId: string;
  datePracticeAdded: any | null;
  id: string;
  marketingMaterialApproved: any | null;
  patientPlansComplete: any | null;
  planId: string | null;
  account: getOnboardingDashboard_getOnboardingDashboard_account;
  plan: getOnboardingDashboard_getOnboardingDashboard_plan | null;
  subscription: getOnboardingDashboard_getOnboardingDashboard_subscription | null;
  practiceActive: any | null;
  practiceClosed: any | null;
  practiceFeeAnalysisComplete: any | null;
  practiceLive: any | null;
  practiceName: string | null;
  practiceProgramApproved: any | null;
  processingComplete: any | null;
  registrationComplete: any | null;
  serviceAgreementComplete: any | null;
  serviceAgreementPending: any | null;
  softwareSetupGuideSent: any | null;
  offices: getOnboardingDashboard_getOnboardingDashboard_offices[] | null;
}

export interface getOnboardingDashboard {
  getOnboardingDashboard: getOnboardingDashboard_getOnboardingDashboard[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertOnboardingDashboard
// ====================================================

export interface upsertOnboardingDashboard_upsertOnboardingDashboard {
  __typename: "OnboardingDashboard";
  datePracticeAdded: any | null;
  id: string;
  marketingMaterialApproved: any | null;
  patientPlansComplete: any | null;
  practiceActive: any | null;
  practiceClosed: any | null;
  practiceFeeAnalysisComplete: any | null;
  practiceLive: any | null;
  practiceName: string | null;
  practiceProgramApproved: any | null;
  processingComplete: any | null;
  registrationComplete: any | null;
  serviceAgreementComplete: any | null;
  serviceAgreementPending: any | null;
  softwareSetupGuideSent: any | null;
}

export interface upsertOnboardingDashboard {
  upsertOnboardingDashboard: upsertOnboardingDashboard_upsertOnboardingDashboard;
}

export interface upsertOnboardingDashboardVariables {
  datePracticeAdded?: any | null;
  id?: string | null;
  marketingMaterialApproved?: any | null;
  patientPlansComplete?: any | null;
  practiceActive?: any | null;
  practiceClosed?: any | null;
  practiceFeeAnalysisComplete?: any | null;
  practiceLive?: any | null;
  practiceName?: string | null;
  practiceProgramApproved?: any | null;
  processingComplete?: any | null;
  registrationComplete?: any | null;
  serviceAgreementComplete?: any | null;
  serviceAgreementPending?: any | null;
  softwareSetupGuideSent?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: closeAccount
// ====================================================

export interface closeAccount_closeAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
}

export interface closeAccount {
  closeAccount: closeAccount_closeAccount;
}

export interface closeAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: revertAccount
// ====================================================

export interface revertAccount_revertAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
}

export interface revertAccount {
  revertAccount: revertAccount_revertAccount;
}

export interface revertAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingStart
// ====================================================

export interface OnboardingStart_meUser_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface OnboardingStart_meUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: OnboardingStart_meUser_google;
}

export interface OnboardingStart {
  meUser: OnboardingStart_meUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitOnboardingStart
// ====================================================

export interface SubmitOnboardingStart_onboardingStart_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface SubmitOnboardingStart_onboardingStart {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: SubmitOnboardingStart_onboardingStart_google;
}

export interface SubmitOnboardingStart {
  onboardingStart: SubmitOnboardingStart_onboardingStart;
}

export interface SubmitOnboardingStartVariables {
  idToken: string;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOnboardingStart
// ====================================================

export interface UpdateOnboardingStart_updateUser_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface UpdateOnboardingStart_updateUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: UpdateOnboardingStart_updateUser_google;
}

export interface UpdateOnboardingStart {
  updateUser: UpdateOnboardingStart_updateUser;
}

export interface UpdateOnboardingStartVariables {
  id: string;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingPractice
// ====================================================

export interface OnboardingPractice_account_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface OnboardingPractice_account {
  __typename: "Account";
  id: string;
  name: string;
  offices: OnboardingPractice_account_offices[];
}

export interface OnboardingPractice {
  account: OnboardingPractice_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitOnboardingPractice
// ====================================================

export interface SubmitOnboardingPractice_onboardingPractice_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface SubmitOnboardingPractice_onboardingPractice {
  __typename: "Account";
  id: string;
  name: string;
  offices: SubmitOnboardingPractice_onboardingPractice_offices[];
}

export interface SubmitOnboardingPractice {
  onboardingPractice: SubmitOnboardingPractice_onboardingPractice;
}

export interface SubmitOnboardingPracticeVariables {
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOnboardingPractice
// ====================================================

export interface UpdateOnboardingPractice_updateAccount {
  __typename: "Account";
  id: string;
  name: string;
}

export interface UpdateOnboardingPractice_updateOffice {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface UpdateOnboardingPractice {
  updateAccount: UpdateOnboardingPractice_updateAccount;
  updateOffice: UpdateOnboardingPractice_updateOffice;
}

export interface UpdateOnboardingPracticeVariables {
  name: string;
  officeId: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingOffices
// ====================================================

export interface OnboardingOffices_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface OnboardingOffices {
  offices: OnboardingOffices_offices[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingDentists
// ====================================================

export interface OnboardingDentists_dentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface OnboardingDentists {
  dentists: OnboardingDentists_dentists[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitOnboardingDentists
// ====================================================

export interface SubmitOnboardingDentists_onboardingDentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface SubmitOnboardingDentists {
  onboardingDentists: SubmitOnboardingDentists_onboardingDentists;
}

export interface SubmitOnboardingDentistsVariables {
  salutation: Salutation;
  name: string;
  degree: Degree;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingComplete
// ====================================================

export interface OnboardingComplete_firstOffice {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface OnboardingComplete_meUser {
  __typename: "User";
  firstName: string;
}

export interface OnboardingComplete {
  firstOffice: OnboardingComplete_firstOffice;
  meUser: OnboardingComplete_meUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitOnboardingComplete
// ====================================================

export interface SubmitOnboardingComplete {
  onboardingComplete: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBillingHistoryAll
// ====================================================

export interface getBillingHistoryAll_getBillingHistoryAll_contact {
  __typename: "Contact";
  id: string;
  secure: string;
}

export interface getBillingHistoryAll_getBillingHistoryAll {
  __typename: "ContactPaymentEvent";
  id: string;
  type: paymentType;
  createdAt: any;
  contact: getBillingHistoryAll_getBillingHistoryAll_contact;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_transStatus: string | null;
  paymentAmount: number;
  paymentDate: any;
  paymentStatus: paymentStatus;
  collectingMethod: collectingMethod;
  paymentNote: string | null;
  gatewayResponse: string | null;
}

export interface getBillingHistoryAll {
  getBillingHistoryAll: getBillingHistoryAll_getBillingHistoryAll[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentsTable
// ====================================================

export interface PaymentsTable_chargesConnection_edges_node {
  __typename: "Payment";
  id: string;
  accountId: string;
  memberId: string;
  secure: string;
  createdAt: number;
  grossAmount: number;
  processingFees: number;
  netAmount: number;
  status: string;
  paymentErrorMessage: string | null;
  creditCard_lastfour: string | null;
  creditCard_type: string | null;
  creditCard_exp: string | null;
  paymentDate: number | null;
  collectingMethod: string | null;
  transactionId: string | null;
}

export interface PaymentsTable_chargesConnection_edges {
  __typename: "PaymentEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: PaymentsTable_chargesConnection_edges_node;
}

export interface PaymentsTable_chargesConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface PaymentsTable_chargesConnection {
  __typename: "PaymentConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (PaymentsTable_chargesConnection_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: PaymentsTable_chargesConnection_pageInfo;
}

export interface PaymentsTable {
  chargesConnection: PaymentsTable_chargesConnection;
}

export interface PaymentsTableVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentsTableSearchByName
// ====================================================

export interface PaymentsTableSearchByName_chargesConnectionSearchByName_edges_node {
  __typename: "Payment";
  accountId: string;
  collectingMethod: string | null;
  createdAt: number;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_type: string | null;
  grossAmount: number;
  id: string;
  memberId: string;
  netAmount: number;
  paymentDate: number | null;
  paymentErrorMessage: string | null;
  processingFees: number;
  secure: string;
  status: string;
  transactionId: string | null;
}

export interface PaymentsTableSearchByName_chargesConnectionSearchByName_edges {
  __typename: "PaymentEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: PaymentsTableSearchByName_chargesConnectionSearchByName_edges_node;
}

export interface PaymentsTableSearchByName_chargesConnectionSearchByName_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
  /**
   * Used to indicate whether more edges exist prior to the set defined by the clients arguments.
   */
  hasPreviousPage: boolean;
  /**
   * The cursor corresponding to the first nodes in edges. Null if the connection is empty.
   */
  startCursor: string | null;
}

export interface PaymentsTableSearchByName_chargesConnectionSearchByName {
  __typename: "PaymentConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (PaymentsTableSearchByName_chargesConnectionSearchByName_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: PaymentsTableSearchByName_chargesConnectionSearchByName_pageInfo;
}

export interface PaymentsTableSearchByName {
  chargesConnectionSearchByName: PaymentsTableSearchByName_chargesConnectionSearchByName;
}

export interface PaymentsTableSearchByNameVariables {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  search: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServicePaymentTable
// ====================================================

export interface ServicePaymentTable_ServicePaymentEvents_edges_node_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
}

export interface ServicePaymentTable_ServicePaymentEvents_edges_node {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  paymentStatus: PaymentStatus;
  subscriberAccount: ServicePaymentTable_ServicePaymentEvents_edges_node_subscriberAccount | null;
}

export interface ServicePaymentTable_ServicePaymentEvents_edges {
  __typename: "ServicePaymentEventEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: ServicePaymentTable_ServicePaymentEvents_edges_node;
}

export interface ServicePaymentTable_ServicePaymentEvents_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface ServicePaymentTable_ServicePaymentEvents {
  __typename: "ServicePaymentEventConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (ServicePaymentTable_ServicePaymentEvents_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: ServicePaymentTable_ServicePaymentEvents_pageInfo;
}

export interface ServicePaymentTable {
  ServicePaymentEvents: ServicePaymentTable_ServicePaymentEvents;
}

export interface ServicePaymentTableVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterServicePayment
// ====================================================

export interface FilterServicePayment_FilterServicePayment_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
}

export interface FilterServicePayment_FilterServicePayment {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  paymentStatus: PaymentStatus;
  subscriberAccount: FilterServicePayment_FilterServicePayment_subscriberAccount | null;
}

export interface FilterServicePayment {
  FilterServicePayment: FilterServicePayment_FilterServicePayment[];
}

export interface FilterServicePaymentVariables {
  search: string;
  paymentStatus?: paymentStatus | null;
  year?: string | null;
  month?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterServicePaymentFailedEvents
// ====================================================

export interface FilterServicePaymentFailedEvents_FilterServicePaymentFailedEvents_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
}

export interface FilterServicePaymentFailedEvents_FilterServicePaymentFailedEvents {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  paymentStatus: PaymentStatus;
  subscriberAccount: FilterServicePaymentFailedEvents_FilterServicePaymentFailedEvents_subscriberAccount | null;
}

export interface FilterServicePaymentFailedEvents {
  FilterServicePaymentFailedEvents: FilterServicePaymentFailedEvents_FilterServicePaymentFailedEvents[];
}

export interface FilterServicePaymentFailedEventsVariables {
  search: string;
  paymentStatus?: paymentStatus | null;
  year?: string | null;
  month?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServicePaymentEventsSearchByName
// ====================================================

export interface ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName_edges_node_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
}

export interface ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName_edges_node {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  paymentStatus: PaymentStatus;
  subscriberAccount: ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName_edges_node_subscriberAccount | null;
}

export interface ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName_edges {
  __typename: "ServicePaymentEventEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName_edges_node;
}

export interface ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName {
  __typename: "ServicePaymentEventConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName_pageInfo;
}

export interface ServicePaymentEventsSearchByName {
  ServicePaymentEventsSearchByName: ServicePaymentEventsSearchByName_ServicePaymentEventsSearchByName;
}

export interface ServicePaymentEventsSearchByNameVariables {
  search: string;
  year?: string | null;
  month?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServicePaymentFailedEventsSearchByName
// ====================================================

export interface ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName_edges_node_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
}

export interface ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName_edges_node {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  paymentStatus: PaymentStatus;
  subscriberAccount: ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName_edges_node_subscriberAccount | null;
}

export interface ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName_edges {
  __typename: "ServicePaymentEventEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName_edges_node;
}

export interface ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName {
  __typename: "ServicePaymentEventConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName_pageInfo;
}

export interface ServicePaymentFailedEventsSearchByName {
  ServicePaymentFailedEventsSearchByName: ServicePaymentFailedEventsSearchByName_ServicePaymentFailedEventsSearchByName;
}

export interface ServicePaymentFailedEventsSearchByNameVariables {
  search: string;
  status?: paymentStatus | null;
  year?: string | null;
  month?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServicePaymentFailedEvents
// ====================================================

export interface ServicePaymentFailedEvents_ServicePaymentFailedEvents_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
}

export interface ServicePaymentFailedEvents_ServicePaymentFailedEvents {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  paymentStatus: PaymentStatus;
  subscriberAccount: ServicePaymentFailedEvents_ServicePaymentFailedEvents_subscriberAccount | null;
}

export interface ServicePaymentFailedEvents {
  ServicePaymentFailedEvents: ServicePaymentFailedEvents_ServicePaymentFailedEvents[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelServicePaymentEvent
// ====================================================

export interface CancelServicePaymentEvent_cancelServicePaymentEvent {
  __typename: "ServicePaymentEvent";
  id: string;
  paymentStatus: PaymentStatus;
}

export interface CancelServicePaymentEvent {
  cancelServicePaymentEvent: CancelServicePaymentEvent_cancelServicePaymentEvent;
}

export interface CancelServicePaymentEventVariables {
  Id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RetryServicePaymentEvent
// ====================================================

export interface RetryServicePaymentEvent_retryServicePaymentEvent {
  __typename: "ServicePaymentEvent";
  id: string;
  paymentStatus: PaymentStatus;
  creditCard_message: string | null;
}

export interface RetryServicePaymentEvent {
  retryServicePaymentEvent: RetryServicePaymentEvent_retryServicePaymentEvent;
}

export interface RetryServicePaymentEventVariables {
  Id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalseReportSearchByName
// ====================================================

export interface SalseReportSearchByName_SalseReportSearchByName_edges_node_invoice {
  __typename: "ServiceInvoice";
  id: string;
  type: paymentType;
  rangeStart: any | null;
  rangeEnd: any | null;
  createdAt: any;
  amount: number;
  status: serviceInvoiceStatus;
  invoiceSequence: number;
  invoiceDate: any;
}

export interface SalseReportSearchByName_SalseReportSearchByName_edges_node_subscriberAccount_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  zip: string;
  state: State;
  phone: string;
  email: string | null;
}

export interface SalseReportSearchByName_SalseReportSearchByName_edges_node_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
  offices: SalseReportSearchByName_SalseReportSearchByName_edges_node_subscriberAccount_offices[];
}

export interface SalseReportSearchByName_SalseReportSearchByName_edges_node {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  invoiceId: string | null;
  invoice: SalseReportSearchByName_SalseReportSearchByName_edges_node_invoice | null;
  paymentStatus: PaymentStatus;
  subscriberAccount: SalseReportSearchByName_SalseReportSearchByName_edges_node_subscriberAccount | null;
}

export interface SalseReportSearchByName_SalseReportSearchByName_edges {
  __typename: "ServicePaymentEventEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: SalseReportSearchByName_SalseReportSearchByName_edges_node;
}

export interface SalseReportSearchByName_SalseReportSearchByName_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface SalseReportSearchByName_SalseReportSearchByName {
  __typename: "ServicePaymentEventConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (SalseReportSearchByName_SalseReportSearchByName_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: SalseReportSearchByName_SalseReportSearchByName_pageInfo;
}

export interface SalseReportSearchByName {
  SalseReportSearchByName: SalseReportSearchByName_SalseReportSearchByName;
}

export interface SalseReportSearchByNameVariables {
  search: string;
  year?: string | null;
  month?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalseReport
// ====================================================

export interface SalseReport_SalseReport_invoice {
  __typename: "ServiceInvoice";
  id: string;
  type: paymentType;
  rangeStart: any | null;
  rangeEnd: any | null;
  createdAt: any;
  amount: number;
  status: serviceInvoiceStatus;
  invoiceSequence: number;
  invoiceDate: any;
}

export interface SalseReport_SalseReport_subscriberAccount_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  zip: string;
  state: State;
  phone: string;
  email: string | null;
}

export interface SalseReport_SalseReport_subscriberAccount {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
  offices: SalseReport_SalseReport_subscriberAccount_offices[];
}

export interface SalseReport_SalseReport {
  __typename: "ServicePaymentEvent";
  id: string;
  subscriptionId: string | null;
  accountId: string;
  subscriberId: string;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_message: string | null;
  creditCard_exp: string | null;
  paymentAmount: number;
  paymentDate: any;
  invoiceId: string | null;
  invoice: SalseReport_SalseReport_invoice | null;
  paymentStatus: PaymentStatus;
  subscriberAccount: SalseReport_SalseReport_subscriberAccount | null;
}

export interface SalseReport {
  SalseReport: SalseReport_SalseReport[];
}

export interface SalseReportVariables {
  search: string;
  year?: string | null;
  month?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContactPaymentsEventsGroupLast
// ====================================================

export interface GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_edges_node {
  __typename: "PaymentProblems";
  id: string;
  accountId: string;
  memberId: string;
  secure: string;
  createdAt: number;
  grossAmount: number;
  processingFees: number;
  netAmount: number;
  status: string;
  paymentErrorMessage: string | null;
  creditCard_lastfour: string | null;
  creditCard_type: string | null;
  creditCard_exp: string | null;
  subscriptionId: string | null;
}

export interface GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_edges {
  __typename: "PaymentProblemsEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_edges_node;
}

export interface GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast {
  __typename: "PaymentProblemsConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast_pageInfo;
}

export interface GetContactPaymentsEventsGroupLast {
  getContactPaymentsEventsGroupLast: GetContactPaymentsEventsGroupLast_getContactPaymentsEventsGroupLast;
}

export interface GetContactPaymentsEventsGroupLastVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContactPaymentEvent
// ====================================================

export interface CreateContactPaymentEvent_createContactPaymentEvent {
  __typename: "ContactPaymentEvent";
  id: string;
}

export interface CreateContactPaymentEvent {
  createContactPaymentEvent: CreateContactPaymentEvent_createContactPaymentEvent;
}

export interface CreateContactPaymentEventVariables {
  contactId: string;
  subscriptionId: string;
  creditCard_lastfour?: string | null;
  creditCard_transStatus?: string | null;
  paymentAmount: number;
  creditCard_type?: string | null;
  type: paymentType;
  paymentStatus: paymentStatus;
  creditCard_message?: string | null;
  creditCard_exp?: string | null;
  paymentDate: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelContactPaymentEvent
// ====================================================

export interface CancelContactPaymentEvent_cancelContactPaymentEvent {
  __typename: "ContactPaymentEvent";
  id: string;
  paymentStatus: paymentStatus;
}

export interface CancelContactPaymentEvent {
  cancelContactPaymentEvent: CancelContactPaymentEvent_cancelContactPaymentEvent;
}

export interface CancelContactPaymentEventVariables {
  Id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentsAnalytics
// ====================================================

export interface PaymentsAnalytics_analytics_cumulativePayouts {
  __typename: "CumulativePayoutsAnalytics";
  month6: number;
}

export interface PaymentsAnalytics_analytics {
  __typename: "Analytics";
  cumulativePayouts: PaymentsAnalytics_analytics_cumulativePayouts;
  nextPayout: number;
}

export interface PaymentsAnalytics {
  analytics: PaymentsAnalytics_analytics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TryNewMemberPayment
// ====================================================

export interface TryNewMemberPayment_tryNewMemberPayment {
  __typename: "ContactPaymentEvent";
  id: string;
  paymentStatus: paymentStatus;
}

export interface TryNewMemberPayment {
  tryNewMemberPayment: TryNewMemberPayment_tryNewMemberPayment;
}

export interface TryNewMemberPaymentVariables {
  id: string;
  paymentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanSheet
// ====================================================

export interface PlanSheet_plan_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface PlanSheet_plan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface PlanSheet_plan_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface PlanSheet_plan {
  __typename: "Plan";
  id: string;
  status: PlanStatus;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  perio: boolean | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  dependentDiscount: number | null;
  allTimeMemberCount: number;
  isDefault: boolean;
  treatments: PlanSheet_plan_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: PlanSheet_plan_discounts[];
  additionalTerms: string | null;
  stripe: PlanSheet_plan_stripe | null;
}

export interface PlanSheet {
  plan: PlanSheet_plan | null;
}

export interface PlanSheetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePlan
// ====================================================

export interface UpdatePlan_updatePlan_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface UpdatePlan_updatePlan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface UpdatePlan_updatePlan_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface UpdatePlan_updatePlan {
  __typename: "Plan";
  id: string;
  status: PlanStatus;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  perio: boolean | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  dependentDiscount: number | null;
  allTimeMemberCount: number;
  isDefault: boolean;
  treatments: UpdatePlan_updatePlan_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: UpdatePlan_updatePlan_discounts[];
  additionalTerms: string | null;
  stripe: UpdatePlan_updatePlan_stripe | null;
}

export interface UpdatePlan {
  updatePlan: UpdatePlan_updatePlan;
}

export interface UpdatePlanVariables {
  id: string;
  name?: string | null;
  minAgeToggle?: boolean | null;
  minAge?: number | null;
  maxAgeToggle?: boolean | null;
  maxAge?: number | null;
  dependentDiscountToggle?: boolean | null;
  dependentDiscount?: number | null;
  perio?: boolean | null;
  annualPriceActive?: boolean | null;
  annualPrice?: number | null;
  monthlyPriceActive?: boolean | null;
  monthlyPrice?: number | null;
  treatments?: TreatmentInput[] | null;
  showValue?: boolean | null;
  globalDiscountActive?: boolean | null;
  globalDiscount?: number | null;
  discounts?: DiscountInput[] | null;
  additionalTerms?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDefaultPlan
// ====================================================

export interface SetDefaultPlan_setDefaultPlan_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface SetDefaultPlan_setDefaultPlan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface SetDefaultPlan_setDefaultPlan_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface SetDefaultPlan_setDefaultPlan {
  __typename: "Plan";
  id: string;
  status: PlanStatus;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  perio: boolean | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  dependentDiscount: number | null;
  allTimeMemberCount: number;
  isDefault: boolean;
  treatments: SetDefaultPlan_setDefaultPlan_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: SetDefaultPlan_setDefaultPlan_discounts[];
  additionalTerms: string | null;
  stripe: SetDefaultPlan_setDefaultPlan_stripe | null;
}

export interface SetDefaultPlan {
  setDefaultPlan: SetDefaultPlan_setDefaultPlan;
}

export interface SetDefaultPlanVariables {
  id: string;
  isDefault: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetPlanStatus
// ====================================================

export interface SetPlanStatus_setPlanStatus {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

export interface SetPlanStatus {
  setPlanStatus: SetPlanStatus_setPlanStatus;
}

export interface SetPlanStatusVariables {
  id: string;
  status: PlanStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePlan
// ====================================================

export interface DeletePlan_deletePlan {
  __typename: "Plan";
  id: string;
}

export interface DeletePlan {
  deletePlan: DeletePlan_deletePlan;
}

export interface DeletePlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlanTemplates
// ====================================================

export interface PlanTemplates_planTemplates {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

export interface PlanTemplates {
  planTemplates: PlanTemplates_planTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DraftPlans
// ====================================================

export interface DraftPlans_plans {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

export interface DraftPlans {
  plans: DraftPlans_plans[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LivePlans
// ====================================================

export interface LivePlans_plans {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

export interface LivePlans_account {
  __typename: "Account";
  id: string;
  programName: string;
}

export interface LivePlans {
  plans: LivePlans_plans[];
  account: LivePlans_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LegacyPlans
// ====================================================

export interface LegacyPlans_plans {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

export interface LegacyPlans {
  plans: LegacyPlans_plans[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServicePlans
// ====================================================

export interface ServicePlans_servicePlans {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

export interface ServicePlans {
  servicePlans: ServicePlans_servicePlans[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDraftFromTemplate
// ====================================================

export interface CreateDraftFromTemplate_createDraftFromTemplate {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

export interface CreateDraftFromTemplate {
  createDraftFromTemplate: CreateDraftFromTemplate_createDraftFromTemplate;
}

export interface CreateDraftFromTemplateVariables {
  templateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClonePlan
// ====================================================

export interface ClonePlan_clonePlan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

export interface ClonePlan {
  clonePlan: ClonePlan_clonePlan;
}

export interface ClonePlanVariables {
  planId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createServicePlan
// ====================================================

export interface createServicePlan_createServicePlan {
  __typename: "Plan";
  activeMemberCount: number;
  additionalTerms: string | null;
  allTimeMemberCount: number;
  annualDependentPrice: number | null;
  annualPrice: number | null;
  annualPriceActive: boolean;
  dependentDiscount: number | null;
  globalDiscount: number | null;
  globalDiscountActive: boolean;
  id: string;
  maxAge: number | null;
  minAge: number | null;
  monthlyDependentPrice: number | null;
  monthlyPrice: number | null;
  monthlyPriceActive: boolean;
  name: string;
  perio: boolean | null;
  setupFee: number | null;
  showValue: boolean;
  status: PlanStatus;
  type: PlanType;
}

export interface createServicePlan {
  createServicePlan: createServicePlan_createServicePlan;
}

export interface createServicePlanVariables {
  annualPrice?: number | null;
  annualPriceActive: boolean;
  monthlyPrice?: number | null;
  monthlyPriceActive: boolean;
  name: string;
  setupFee?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePracticeName
// ====================================================

export interface UpdatePracticeName_updateAccount {
  __typename: "Account";
  id: string;
  name: string;
}

export interface UpdatePracticeName {
  updateAccount: UpdatePracticeName_updateAccount;
}

export interface UpdatePracticeNameVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProgramName
// ====================================================

export interface UpdateProgramName_updateAccount {
  __typename: "Account";
  id: string;
  programName: string;
}

export interface UpdateProgramName {
  updateAccount: UpdateProgramName_updateAccount;
}

export interface UpdateProgramNameVariables {
  programName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RenewalsTable
// ====================================================

export interface RenewalsTable_renewalsConnection_edges_node_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface RenewalsTable_renewalsConnection_edges_node_subscriptions {
  __typename: "ContactPlanSubscription";
  startDate: any;
  endDate: any;
  isEnabled: boolean;
  isCancelled: boolean;
  autoRenewal: boolean;
  status: PlanSubscriptionStatus;
  renewalSubscriptionId: string | null;
}

export interface RenewalsTable_renewalsConnection_edges_node_subscriptionsHistory {
  __typename: "ContactPlanSubscription";
  startDate: any;
  endDate: any;
  isEnabled: boolean;
  isCancelled: boolean;
  autoRenewal: boolean;
  status: PlanSubscriptionStatus;
  renewalSubscriptionId: string | null;
}

export interface RenewalsTable_renewalsConnection_edges_node {
  __typename: "Contact";
  id: string;
  secure: string;
  plan: RenewalsTable_renewalsConnection_edges_node_plan | null;
  subscriptions: RenewalsTable_renewalsConnection_edges_node_subscriptions[] | null;
  subscriptionsHistory: RenewalsTable_renewalsConnection_edges_node_subscriptionsHistory[] | null;
}

export interface RenewalsTable_renewalsConnection_edges {
  __typename: "ContactEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: RenewalsTable_renewalsConnection_edges_node;
}

export interface RenewalsTable_renewalsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface RenewalsTable_renewalsConnection {
  __typename: "ContactConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (RenewalsTable_renewalsConnection_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: RenewalsTable_renewalsConnection_pageInfo;
}

export interface RenewalsTable {
  renewalsConnection: RenewalsTable_renewalsConnection;
}

export interface RenewalsTableVariables {
  upcoming?: boolean | null;
  archived?: boolean | null;
  expired?: boolean | null;
  renewed?: boolean | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RenewalsTableSearch
// ====================================================

export interface RenewalsTableSearch_renewalsConnectionSearch_edges_node_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface RenewalsTableSearch_renewalsConnectionSearch_edges_node_subscriptions {
  __typename: "ContactPlanSubscription";
  startDate: any;
  endDate: any;
  isEnabled: boolean;
  isCancelled: boolean;
  autoRenewal: boolean;
  status: PlanSubscriptionStatus;
}

export interface RenewalsTableSearch_renewalsConnectionSearch_edges_node {
  __typename: "Contact";
  id: string;
  secure: string;
  plan: RenewalsTableSearch_renewalsConnectionSearch_edges_node_plan | null;
  subscriptions: RenewalsTableSearch_renewalsConnectionSearch_edges_node_subscriptions[] | null;
}

export interface RenewalsTableSearch_renewalsConnectionSearch_edges {
  __typename: "ContactEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: RenewalsTableSearch_renewalsConnectionSearch_edges_node;
}

export interface RenewalsTableSearch_renewalsConnectionSearch_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface RenewalsTableSearch_renewalsConnectionSearch {
  __typename: "ContactConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (RenewalsTableSearch_renewalsConnectionSearch_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: RenewalsTableSearch_renewalsConnectionSearch_pageInfo;
}

export interface RenewalsTableSearch {
  renewalsConnectionSearch: RenewalsTableSearch_renewalsConnectionSearch;
}

export interface RenewalsTableSearchVariables {
  search: string;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RenewalsAnalytics
// ====================================================

export interface RenewalsAnalytics_analytics_subscriptions {
  __typename: "SubscriptionsAnalytics";
  totalMemeberActive: number;
  monthlyRenewalCount: number;
  monthlyCancelledCount: number;
  totalArchived: number;
  monthlyRenewedCount: number;
  monthlyExpiredCount: number;
}

export interface RenewalsAnalytics_analytics {
  __typename: "Analytics";
  subscriptions: RenewalsAnalytics_analytics_subscriptions;
}

export interface RenewalsAnalytics {
  analytics: RenewalsAnalytics_analytics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getServicePlanSubscriptionsList
// ====================================================

export interface getServicePlanSubscriptionsList_getServicePlanSubscriptionsList_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface getServicePlanSubscriptionsList_getServicePlanSubscriptionsList_account {
  __typename: "Account";
  id: string;
  name: string;
  programName: string;
  clearentPaymentKey: string | null;
}

export interface getServicePlanSubscriptionsList_getServicePlanSubscriptionsList_practice {
  __typename: "prac";
  practiceName: string;
  accountId: string;
  accountStatus: string;
}

export interface getServicePlanSubscriptionsList_getServicePlanSubscriptionsList {
  __typename: "ServicePlanSubscription";
  id: string;
  plan: getServicePlanSubscriptionsList_getServicePlanSubscriptionsList_plan | null;
  account: getServicePlanSubscriptionsList_getServicePlanSubscriptionsList_account;
  startDate: any | null;
  endDate: any | null;
  firstBilling: any | null;
  setupFee: number;
  fee: number;
  status: PlanSubscriptionStatus;
  lastPaymentDate: any | null;
  isEnabled: boolean;
  isCancelled: boolean;
  acceptDate: any | null;
  creditCard_desc: string | null;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_token: string | null;
  creditCard_type: string | null;
  fullName: string | null;
  agreementURL: string | null;
  practice: getServicePlanSubscriptionsList_getServicePlanSubscriptionsList_practice[] | null;
}

export interface getServicePlanSubscriptionsList {
  getServicePlanSubscriptionsList: getServicePlanSubscriptionsList_getServicePlanSubscriptionsList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServiceSubscriptionLists
// ====================================================

export interface ServiceSubscriptionLists_ServicePlanSubscriptions_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface ServiceSubscriptionLists_ServicePlanSubscriptions_account {
  __typename: "Account";
  id: string;
  name: string;
  programName: string;
  clearentPaymentKey: string | null;
}

export interface ServiceSubscriptionLists_ServicePlanSubscriptions_practice {
  __typename: "prac";
  practiceName: string;
  accountId: string;
}

export interface ServiceSubscriptionLists_ServicePlanSubscriptions {
  __typename: "ServicePlanSubscription";
  id: string;
  plan: ServiceSubscriptionLists_ServicePlanSubscriptions_plan | null;
  account: ServiceSubscriptionLists_ServicePlanSubscriptions_account;
  startDate: any | null;
  endDate: any | null;
  firstBilling: any | null;
  setupFee: number;
  fee: number;
  status: PlanSubscriptionStatus;
  cancelAt: any | null;
  lastPaymentDate: any | null;
  isEnabled: boolean;
  isCancelled: boolean;
  acceptDate: any | null;
  creditCard_desc: string | null;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_token: string | null;
  creditCard_type: string | null;
  fullName: string | null;
  agreementURL: string | null;
  practice: ServiceSubscriptionLists_ServicePlanSubscriptions_practice[] | null;
}

export interface ServiceSubscriptionLists {
  ServicePlanSubscriptions: ServiceSubscriptionLists_ServicePlanSubscriptions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OwnServiceSubscriptionLists
// ====================================================

export interface OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges_node_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges_node_account {
  __typename: "Account";
  id: string;
  name: string;
  programName: string;
  clearentPaymentKey: string | null;
}

export interface OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges_node_practice {
  __typename: "prac";
  practiceName: string;
  accountId: string;
}

export interface OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges_node {
  __typename: "ServicePlanSubscription";
  id: string;
  plan: OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges_node_plan | null;
  account: OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges_node_account;
  startDate: any | null;
  endDate: any | null;
  setupFee: number;
  fee: number;
  status: PlanSubscriptionStatus;
  lastPaymentDate: any | null;
  isEnabled: boolean;
  isCancelled: boolean;
  acceptDate: any | null;
  creditCard_desc: string | null;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_token: string | null;
  creditCard_type: string | null;
  fullName: string | null;
  agreementURL: string | null;
  practice: OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges_node_practice[] | null;
}

export interface OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges {
  __typename: "ServicePlanSubscriptionEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges_node;
}

export interface OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface OwnServiceSubscriptionLists_OwnServicePlanSubscriptions {
  __typename: "ServicePlanSubscriptionConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: OwnServiceSubscriptionLists_OwnServicePlanSubscriptions_pageInfo;
}

export interface OwnServiceSubscriptionLists {
  OwnServicePlanSubscriptions: OwnServiceSubscriptionLists_OwnServicePlanSubscriptions;
}

export interface OwnServiceSubscriptionListsVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OwnServicePlanSubscriptionsSearch
// ====================================================

export interface OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges_node_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges_node_account {
  __typename: "Account";
  id: string;
  name: string;
  programName: string;
  clearentPaymentKey: string | null;
}

export interface OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges_node_practice {
  __typename: "prac";
  practiceName: string;
  accountId: string;
  accountStatus: string;
}

export interface OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges_node {
  __typename: "ServicePlanSubscription";
  id: string;
  plan: OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges_node_plan | null;
  account: OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges_node_account;
  startDate: any | null;
  endDate: any | null;
  setupFee: number;
  fee: number;
  status: PlanSubscriptionStatus;
  lastPaymentDate: any | null;
  isEnabled: boolean;
  isCancelled: boolean;
  acceptDate: any | null;
  creditCard_desc: string | null;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_token: string | null;
  creditCard_type: string | null;
  fullName: string | null;
  agreementURL: string | null;
  practice: OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges_node_practice[] | null;
}

export interface OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges {
  __typename: "ServicePlanSubscriptionEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges_node;
}

export interface OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch {
  __typename: "ServicePlanSubscriptionConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch_pageInfo;
}

export interface OwnServicePlanSubscriptionsSearch {
  OwnServicePlanSubscriptionsSearch: OwnServicePlanSubscriptionsSearch_OwnServicePlanSubscriptionsSearch;
}

export interface OwnServicePlanSubscriptionsSearchVariables {
  search: string;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActiveServicesAgreementContract
// ====================================================

export interface ActiveServicesAgreementContract_activeServicesAgreementContract_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface ActiveServicesAgreementContract_activeServicesAgreementContract_account {
  __typename: "Account";
  id: string;
  name: string;
  programName: string;
  clearentPaymentKey: string | null;
}

export interface ActiveServicesAgreementContract_activeServicesAgreementContract {
  __typename: "ServicePlanSubscription";
  id: string;
  plan: ActiveServicesAgreementContract_activeServicesAgreementContract_plan | null;
  account: ActiveServicesAgreementContract_activeServicesAgreementContract_account;
  startDate: any | null;
  endDate: any | null;
  setupFee: number;
  fee: number;
  status: PlanSubscriptionStatus;
  isEnabled: boolean;
  isCancelled: boolean;
  acceptDate: any | null;
  creditCard_desc: string | null;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_token: string | null;
  creditCard_type: string | null;
  fullName: string | null;
  agreementURL: string | null;
}

export interface ActiveServicesAgreementContract {
  activeServicesAgreementContract: ActiveServicesAgreementContract_activeServicesAgreementContract;
}

export interface ActiveServicesAgreementContractVariables {
  id: string;
  fullName?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteServicePlan
// ====================================================

export interface DeleteServicePlan_deleteServicePlan {
  __typename: "ServicePlanSubscription";
  id: string;
}

export interface DeleteServicePlan {
  deleteServicePlan: DeleteServicePlan_deleteServicePlan;
}

export interface DeleteServicePlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelServicePlan
// ====================================================

export interface CancelServicePlan_cancelServicePlan {
  __typename: "ServicePlanSubscription";
  id: string;
}

export interface CancelServicePlan {
  cancelServicePlan: CancelServicePlan_cancelServicePlan;
}

export interface CancelServicePlanVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EnrollService
// ====================================================

export interface EnrollService_enrollService_plan {
  __typename: "Plan";
  id: string;
  name: string;
}

export interface EnrollService_enrollService_account {
  __typename: "Account";
  id: string;
  name: string;
  programName: string;
  clearentPaymentKey: string | null;
}

export interface EnrollService_enrollService {
  __typename: "ServicePlanSubscription";
  id: string;
  plan: EnrollService_enrollService_plan | null;
  account: EnrollService_enrollService_account;
  startDate: any | null;
  endDate: any | null;
  setupFee: number;
  fee: number;
  status: PlanSubscriptionStatus;
  isEnabled: boolean;
  isCancelled: boolean;
  acceptDate: any | null;
  creditCard_desc: string | null;
  creditCard_exp: string | null;
  creditCard_lastfour: string | null;
  creditCard_token: string | null;
  creditCard_type: string | null;
  fullName: string | null;
  agreementURL: string | null;
}

export interface EnrollService {
  enrollService: EnrollService_enrollService;
}

export interface EnrollServiceVariables {
  planId: string;
  setupFee: number;
  serviceFee: number;
  firstBilling: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: serviceBillingHistory
// ====================================================

export interface serviceBillingHistory_serviceBillingHistory {
  __typename: "ServiceBillingHistory";
  type: string | null;
  amount: number | null;
  paymentDate: string | null;
  createdAt: string | null;
  id: string | null;
  last4: string | null;
  cardExp: string | null;
  plan: string | null;
  status: string | null;
  invoiceNumber: string | null;
  paymentErrorMessage: string | null;
}

export interface serviceBillingHistory {
  serviceBillingHistory: serviceBillingHistory_serviceBillingHistory[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServiceInvoicesTable
// ====================================================

export interface ServiceInvoicesTable_serviceInvoices_account {
  __typename: "Account";
  code: string;
  name: string;
}

export interface ServiceInvoicesTable_serviceInvoices_subscriberAccount {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
}

export interface ServiceInvoicesTable_serviceInvoices {
  __typename: "ServiceInvoice";
  id: string;
  type: paymentType;
  invoiceDate: any;
  status: serviceInvoiceStatus;
  invoiceSequence: number;
  rangeEnd: any | null;
  amount: number;
  rangeStart: any | null;
  account: ServiceInvoicesTable_serviceInvoices_account;
  subscriberAccount: ServiceInvoicesTable_serviceInvoices_subscriberAccount | null;
}

export interface ServiceInvoicesTable {
  serviceInvoices: ServiceInvoicesTable_serviceInvoices[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServiceInvoiceEvent
// ====================================================

export interface ServiceInvoiceEvent_ServiceInvoiceEvent_edges_node_account {
  __typename: "Account";
  code: string;
  name: string;
}

export interface ServiceInvoiceEvent_ServiceInvoiceEvent_edges_node_subscriberAccount {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
}

export interface ServiceInvoiceEvent_ServiceInvoiceEvent_edges_node {
  __typename: "ServiceInvoice";
  id: string;
  type: paymentType;
  invoiceDate: any;
  status: serviceInvoiceStatus;
  invoiceSequence: number;
  rangeEnd: any | null;
  amount: number;
  rangeStart: any | null;
  account: ServiceInvoiceEvent_ServiceInvoiceEvent_edges_node_account;
  subscriberAccount: ServiceInvoiceEvent_ServiceInvoiceEvent_edges_node_subscriberAccount | null;
}

export interface ServiceInvoiceEvent_ServiceInvoiceEvent_edges {
  __typename: "ServiceInvoiceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: ServiceInvoiceEvent_ServiceInvoiceEvent_edges_node;
}

export interface ServiceInvoiceEvent_ServiceInvoiceEvent_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface ServiceInvoiceEvent_ServiceInvoiceEvent {
  __typename: "ServiceInvoiceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (ServiceInvoiceEvent_ServiceInvoiceEvent_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: ServiceInvoiceEvent_ServiceInvoiceEvent_pageInfo;
}

export interface ServiceInvoiceEvent {
  ServiceInvoiceEvent: ServiceInvoiceEvent_ServiceInvoiceEvent;
}

export interface ServiceInvoiceEventVariables {
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: serviceInvoicesSearchByName
// ====================================================

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_subscription_plan {
  __typename: "Plan";
  name: string;
}

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_subscription {
  __typename: "ServicePlanSubscription";
  plan: serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_subscription_plan | null;
}

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_account_offices {
  __typename: "Office";
  name: string;
  email: string | null;
  address1: string;
  address2: string | null;
  phone: string;
  city: string;
  state: State;
  zip: string;
}

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_account {
  __typename: "Account";
  code: string;
  name: string;
  offices: serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_account_offices[];
}

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_subscriberAccount {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
}

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node {
  __typename: "ServiceInvoice";
  id: string;
  type: paymentType;
  invoiceDate: any;
  status: serviceInvoiceStatus;
  invoiceSequence: number;
  rangeEnd: any | null;
  amount: number;
  rangeStart: any | null;
  subscription: serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_subscription | null;
  account: serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_account;
  subscriberAccount: serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node_subscriberAccount | null;
}

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges {
  __typename: "ServiceInvoiceEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges_node;
}

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface serviceInvoicesSearchByName_serviceInvoicesSearchByName {
  __typename: "ServiceInvoiceConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (serviceInvoicesSearchByName_serviceInvoicesSearchByName_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: serviceInvoicesSearchByName_serviceInvoicesSearchByName_pageInfo;
}

export interface serviceInvoicesSearchByName {
  serviceInvoicesSearchByName: serviceInvoicesSearchByName_serviceInvoicesSearchByName;
}

export interface serviceInvoicesSearchByNameVariables {
  search: string;
  year?: string | null;
  month?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterServiceInvoices
// ====================================================

export interface FilterServiceInvoices_FilterServiceInvoices_account {
  __typename: "Account";
  code: string;
  name: string;
}

export interface FilterServiceInvoices_FilterServiceInvoices_subscriberAccount {
  __typename: "Account";
  id: string;
  code: string;
  name: string;
}

export interface FilterServiceInvoices_FilterServiceInvoices {
  __typename: "ServiceInvoice";
  id: string;
  type: paymentType;
  invoiceDate: any;
  status: serviceInvoiceStatus;
  invoiceSequence: number;
  rangeEnd: any | null;
  amount: number;
  rangeStart: any | null;
  account: FilterServiceInvoices_FilterServiceInvoices_account;
  subscriberAccount: FilterServiceInvoices_FilterServiceInvoices_subscriberAccount | null;
}

export interface FilterServiceInvoices {
  FilterServiceInvoices: FilterServiceInvoices_FilterServiceInvoices[];
}

export interface FilterServiceInvoicesVariables {
  search: string;
  year?: string | null;
  month?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SettingsBank
// ====================================================

export interface SettingsBank_account_stripe {
  __typename: "StripeAccountFields";
  id: string;
  loginLink: string;
  acceptPayments: boolean;
  bankTransfers: boolean;
  required: string | null;
  verifying: string | null;
}

export interface SettingsBank_account {
  __typename: "Account";
  id: string;
  stripe: SettingsBank_account_stripe | null;
}

export interface SettingsBank {
  account: SettingsBank_account | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SettingsPractice
// ====================================================

export interface SettingsPractice_account {
  __typename: "Account";
  id: string;
  name: string;
}

export interface SettingsPractice_users_google {
  __typename: "GoogleUserFields";
  email: string;
  role: UserRole;
  verified: boolean;
}

export interface SettingsPractice_users {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: SettingsPractice_users_google;
}

export interface SettingsPractice_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  zip: string;
  state: State;
  phone: string;
  email: string | null;
}

export interface SettingsPractice_dentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface SettingsPractice {
  account: SettingsPractice_account | null;
  users: SettingsPractice_users[];
  offices: SettingsPractice_offices[];
  dentists: SettingsPractice_dentists[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBusinessDetail
// ====================================================

export interface updateBusinessDetail_updateBusinessDetail {
  __typename: "Account";
  acceptPayments: boolean;
  clearentApiKey: string | null;
  clearentPaymentKey: string | null;
  code: string;
  id: string;
  logoUrl: string | null;
  merchantNumber: string | null;
  name: string;
  programName: string;
  siteColor: SiteColor;
  siteImage: SiteImage;
  sitePhone: string;
  siteUrl: string | null;
  siteVideoUrl: string | null;
  slug: string | null;
}

export interface updateBusinessDetail {
  updateBusinessDetail: updateBusinessDetail_updateBusinessDetail;
}

export interface updateBusinessDetailVariables {
  clearentApiKey?: string | null;
  clearentPaymentKey?: string | null;
  merchantNumber?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectStripeAccount
// ====================================================

export interface ConnectStripeAccount_connectStripeAccount_stripe {
  __typename: "StripeAccountFields";
  id: string;
  loginLink: string;
  acceptPayments: boolean;
  bankTransfers: boolean;
  required: string | null;
  verifying: string | null;
}

export interface ConnectStripeAccount_connectStripeAccount {
  __typename: "Account";
  id: string;
  stripe: ConnectStripeAccount_connectStripeAccount_stripe | null;
}

export interface ConnectStripeAccount {
  connectStripeAccount: ConnectStripeAccount_connectStripeAccount;
}

export interface ConnectStripeAccountVariables {
  authorizationCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCustomerActivityReport
// ====================================================

export interface getCustomerActivityReport_getCustomerActivityReport {
  __typename: "CustomerActivityReport";
  year: number;
  month: number;
  newRegistrations: number;
  completedServiceAgreements: number;
  cancellations: number;
}

export interface getCustomerActivityReport {
  getCustomerActivityReport: getCustomerActivityReport_getCustomerActivityReport[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getConversionActivityReport
// ====================================================

export interface getConversionActivityReport_getConversionActivityReport {
  __typename: "ConversionActivityReport";
  year: number;
  month: number;
  newRegistrations: number;
  pendingServiceAgreements: number;
  completedServiceAgreements: number;
  cancellations: number;
}

export interface getConversionActivityReport {
  getConversionActivityReport: getConversionActivityReport_getConversionActivityReport[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSalesCycleReport
// ====================================================

export interface getSalesCycleReport_getSalesCycleReport {
  __typename: "SalesCycleReport";
  year: number;
  month: number;
  newRegistrations: number;
  completedServiceAgreements: number;
  cancellations: number;
  currentMonth: number;
  month1: number;
  month2: number;
  month3: number;
}

export interface getSalesCycleReport {
  getSalesCycleReport: getSalesCycleReport_getSalesCycleReport[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBillingReport
// ====================================================

export interface getBillingReport_getBillingReport {
  __typename: "BillingReport";
  year: number;
  month: number;
  customersBillRefunded: number;
  customersBillFailed: number;
  customerInvoiced: number;
  customerPaid: number;
}

export interface getBillingReport {
  getBillingReport: getBillingReport_getBillingReport[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: transactionLogs
// ====================================================

export interface transactionLogs_transactionLogs_edges_node_account {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
}

export interface transactionLogs_transactionLogs_edges_node_user {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
}

export interface transactionLogs_transactionLogs_edges_node_contact {
  __typename: "Contact";
  id: string;
  code: string;
  secureNotes: string | null;
  secure: string;
}

export interface transactionLogs_transactionLogs_edges_node {
  __typename: "TransactionLog";
  id: string;
  accountId: string | null;
  action: string;
  account: transactionLogs_transactionLogs_edges_node_account | null;
  userId: string | null;
  user: transactionLogs_transactionLogs_edges_node_user | null;
  contactId: string | null;
  contact: transactionLogs_transactionLogs_edges_node_contact | null;
  old: string | null;
  new: string | null;
  errorMessage: string | null;
  createdAt: any;
  member: string | null;
}

export interface transactionLogs_transactionLogs_edges {
  __typename: "TransactionLogEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: transactionLogs_transactionLogs_edges_node;
}

export interface transactionLogs_transactionLogs_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface transactionLogs_transactionLogs {
  __typename: "TransactionLogConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (transactionLogs_transactionLogs_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: transactionLogs_transactionLogs_pageInfo;
}

export interface transactionLogs {
  transactionLogs: transactionLogs_transactionLogs;
}

export interface transactionLogsVariables {
  search: string;
  startDate?: string | null;
  endDate?: string | null;
  filterType?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface CreateUser_createUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: CreateUser_createUser_google;
}

export interface CreateUser {
  createUser: CreateUser_createUser;
}

export interface CreateUserVariables {
  email: string;
  firstName: string;
  lastName: string;
  role?: UserRole | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: UpdateUser_updateUser_google;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role?: UserRole | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUser
// ====================================================

export interface DeleteUser_deleteUser {
  __typename: "User";
  id: string;
}

export interface DeleteUser {
  deleteUser: DeleteUser_deleteUser;
}

export interface DeleteUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsers
// ====================================================

export interface GetUsers_users_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface GetUsers_users {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: GetUsers_users_google;
}

export interface GetUsers {
  users: GetUsers_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserSheetResendInvite
// ====================================================

export interface UserSheetResendInvite_resendUserInvite {
  __typename: "User";
  id: string;
}

export interface UserSheetResendInvite {
  resendUserInvite: UserSheetResendInvite_resendUserInvite;
}

export interface UserSheetResendInviteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersTable
// ====================================================

export interface UsersTable_users_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface UsersTable_users {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: UsersTable_users_google;
}

export interface UsersTable {
  users: UsersTable_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UsersTableResendInvite
// ====================================================

export interface UsersTableResendInvite_resendUserInvite {
  __typename: "User";
  id: string;
}

export interface UsersTableResendInvite {
  resendUserInvite: UsersTableResendInvite_resendUserInvite;
}

export interface UsersTableResendInviteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVideos
// ====================================================

export interface GetVideos_videos {
  __typename: "Video";
  id: string;
  index: number;
  title: string;
  url: string;
  status: VideoStatus;
}

export interface GetVideos {
  videos: GetVideos_videos[];
}

export interface GetVideosVariables {
  status?: VideoStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: videoSearch
// ====================================================

export interface videoSearch_videoSearch_edges_node {
  __typename: "Video";
  id: string;
  index: number;
  title: string;
  url: string;
  status: VideoStatus;
}

export interface videoSearch_videoSearch_edges {
  __typename: "VideoEdge";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Cursor
   */
  cursor: string;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Node
   */
  node: videoSearch_videoSearch_edges_node;
}

export interface videoSearch_videoSearch_pageInfo {
  __typename: "PageInfo";
  /**
   * The cursor corresponding to the last nodes in edges. Null if the connection is empty.
   */
  endCursor: string | null;
  /**
   * Used to indicate whether more edges exist following the set defined by the clients arguments.
   */
  hasNextPage: boolean;
}

export interface videoSearch_videoSearch {
  __typename: "VideoConnection";
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
   */
  edges: (videoSearch_videoSearch_edges | null)[] | null;
  /**
   * https: // facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
   */
  pageInfo: videoSearch_videoSearch_pageInfo;
}

export interface videoSearch {
  videoSearch: videoSearch_videoSearch;
}

export interface videoSearchVariables {
  search: string;
  indexOrder: number;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: singleVideo
// ====================================================

export interface singleVideo_singleVideo {
  __typename: "Video";
  id: string;
  index: number;
  title: string;
  url: string;
  status: VideoStatus;
}

export interface singleVideo {
  singleVideo: singleVideo_singleVideo | null;
}

export interface singleVideoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addVideo
// ====================================================

export interface addVideo_addVideo {
  __typename: "Video";
  id: string;
  index: number;
  title: string;
  url: string;
  status: VideoStatus;
}

export interface addVideo {
  addVideo: addVideo_addVideo;
}

export interface addVideoVariables {
  index: number;
  title: string;
  url: string;
  status: VideoStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateVideo
// ====================================================

export interface updateVideo_updateVideo {
  __typename: "Video";
  id: string;
  index: number;
  title: string;
  url: string;
  status: VideoStatus;
}

export interface updateVideo {
  updateVideo: updateVideo_updateVideo;
}

export interface updateVideoVariables {
  id: string;
  index: number;
  title: string;
  url: string;
  status: VideoStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteVideo
// ====================================================

export interface deleteVideo_deleteVideo {
  __typename: "Video";
  id: string;
}

export interface deleteVideo {
  deleteVideo: deleteVideo_deleteVideo;
}

export interface deleteVideoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: dentistFields
// ====================================================

export interface dentistFields {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: faqFields
// ====================================================

export interface faqFields {
  __typename: "FAQ";
  id: string;
  index: number;
  question: string;
  answer: string;
  status: FAQStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlyerFields
// ====================================================

export interface FlyerFields {
  __typename: "Flyer";
  id: string;
  index: number;
  title: string;
  url: string;
  status: FlyerStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: officeFields
// ====================================================

export interface officeFields {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: onboardingDashboard
// ====================================================

export interface onboardingDashboard_account {
  __typename: "Account";
  name: string;
  code: string;
  status: AccountStatus;
}

export interface onboardingDashboard_plan {
  __typename: "Plan";
  name: string;
  status: PlanStatus;
  type: PlanType;
}

export interface onboardingDashboard_subscription {
  __typename: "ServicePlanSubscription";
  createdAt: any;
  acceptDate: any | null;
  startDate: any | null;
  endDate: any | null;
  fee: number;
  status: PlanSubscriptionStatus;
}

export interface onboardingDashboard_offices {
  __typename: "Office";
  address1: string;
  address2: string | null;
  city: string;
  zip: string;
  state: State;
  phone: string;
  email: string | null;
}

export interface onboardingDashboard {
  __typename: "OnboardingDashboard";
  accountId: string;
  datePracticeAdded: any | null;
  id: string;
  marketingMaterialApproved: any | null;
  patientPlansComplete: any | null;
  planId: string | null;
  account: onboardingDashboard_account;
  plan: onboardingDashboard_plan | null;
  subscription: onboardingDashboard_subscription | null;
  practiceActive: any | null;
  practiceClosed: any | null;
  practiceFeeAnalysisComplete: any | null;
  practiceLive: any | null;
  practiceName: string | null;
  practiceProgramApproved: any | null;
  processingComplete: any | null;
  registrationComplete: any | null;
  serviceAgreementComplete: any | null;
  serviceAgreementPending: any | null;
  softwareSetupGuideSent: any | null;
  offices: onboardingDashboard_offices[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: userFields
// ====================================================

export interface userFields_google {
  __typename: "GoogleUserFields";
  email: string;
  verified: boolean;
  role: UserRole;
}

export interface userFields {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  google: userFields_google;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: accountFields
// ====================================================

export interface accountFields_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface accountFields {
  __typename: "Account";
  id: string;
  name: string;
  offices: accountFields_offices[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: planSheetFields
// ====================================================

export interface planSheetFields_treatments {
  __typename: "Treatment";
  id: string;
  name: string;
  code: string;
  quantity: number;
  value: number;
}

export interface planSheetFields_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface planSheetFields_stripe {
  __typename: "StripePlanFields";
  createdAt: number;
}

export interface planSheetFields {
  __typename: "Plan";
  id: string;
  status: PlanStatus;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  perio: boolean | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  dependentDiscount: number | null;
  allTimeMemberCount: number;
  isDefault: boolean;
  treatments: planSheetFields_treatments[];
  showValue: boolean;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  discounts: planSheetFields_discounts[];
  additionalTerms: string | null;
  stripe: planSheetFields_stripe | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: planGridFields
// ====================================================

export interface planGridFields {
  __typename: "Plan";
  id: string;
  name: string;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  status: PlanStatus;
  allTimeMemberCount: number;
  isDefault: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VideoFields
// ====================================================

export interface VideoFields {
  __typename: "Video";
  id: string;
  index: number;
  title: string;
  url: string;
  status: VideoStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  CLOSED = "CLOSED",
  CLOSE_PENDING = "CLOSE_PENDING",
  PENDING = "PENDING",
  SUSPENDED = "SUSPENDED",
}

export enum AccountType {
  PRACTICE = "PRACTICE",
  SYSTEM = "SYSTEM",
}

export enum CommunicationTemplateType {
  INVITE_EMAIL = "INVITE_EMAIL",
  INVITE_TEXT = "INVITE_TEXT",
  WELCOME_EMAIL = "WELCOME_EMAIL",
}

export enum Degree {
  DDS = "DDS",
  DMD = "DMD",
  NONE = "NONE",
}

export enum DiscountCategory {
  ADJUNCTIVE_GENERAL_SERVICES = "ADJUNCTIVE_GENERAL_SERVICES",
  DIAGNOSTIC = "DIAGNOSTIC",
  ENDODONTICS = "ENDODONTICS",
  IMPLANT_SERVICES = "IMPLANT_SERVICES",
  MAXILLOFACIAL_PROSTHETICS = "MAXILLOFACIAL_PROSTHETICS",
  ORAL_AND_MAXILLOFACIAL_SURGERY = "ORAL_AND_MAXILLOFACIAL_SURGERY",
  ORTHODONTICS = "ORTHODONTICS",
  PERIODONTICS = "PERIODONTICS",
  PREVENTIVE = "PREVENTIVE",
  PROSTHODONTICS_FIXED = "PROSTHODONTICS_FIXED",
  PROSTHODONTICS_REMOVABLE = "PROSTHODONTICS_REMOVABLE",
  RESTORATIVE = "RESTORATIVE",
}

export enum FAQStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum FlyerStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum OnboardingType {
  INITIAL = "INITIAL",
  NEW_PLAN = "NEW_PLAN",
}

export enum PaymentStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  CREATED = "CREATED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  REFUND_COMPLETED = "REFUND_COMPLETED",
  REFUND_FAILED = "REFUND_FAILED",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REQUESTED = "REQUESTED",
  VOIDED = "VOIDED",
}

export enum PlanStatus {
  DRAFT = "DRAFT",
  LEGACY = "LEGACY",
  LIVE = "LIVE",
  TEMPLATE = "TEMPLATE",
}

export enum PlanSubscriptionStatus {
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  CANCEL_PENDING = "CANCEL_PENDING",
  EXPIRED = "EXPIRED",
  LEGACY = "LEGACY",
  MANUAL = "MANUAL",
  PENDING = "PENDING",
  PROPOSED = "PROPOSED",
  SUSPENDED = "SUSPENDED",
}

export enum PlanType {
  MEMBERSHIP = "MEMBERSHIP",
  SERVICE = "SERVICE",
}

export enum Salutation {
  Dr = "Dr",
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
}

export enum SiteColor {
  BLUE = "BLUE",
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  RED = "RED",
}

export enum SiteImage {
  COUPLE = "COUPLE",
  MAN = "MAN",
  WOMAN = "WOMAN",
}

export enum State {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AS = "AS",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  GU = "GU",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UM = "UM",
  UT = "UT",
  VA = "VA",
  VI = "VI",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export enum UserRole {
  ADMIN = "ADMIN",
  STANDARD = "STANDARD",
  SUPERADMIN = "SUPERADMIN",
}

export enum VideoStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum WidgetIcon {
  MEDAL = "MEDAL",
  NONE = "NONE",
}

export enum collectingMethod {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum invoiceStatus {
  CANCELLED = "CANCELLED",
  CREATED = "CREATED",
  FAILED = "FAILED",
  OPEN = "OPEN",
  PAID = "PAID",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
}

export enum paymentStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  CREATED = "CREATED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  REFUND_COMPLETED = "REFUND_COMPLETED",
  REFUND_FAILED = "REFUND_FAILED",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REQUESTED = "REQUESTED",
  VOIDED = "VOIDED",
}

export enum paymentType {
  ADDITIONAL_FEE = "ADDITIONAL_FEE",
  OTHER = "OTHER",
  SUBS = "SUBS",
  VENDOR_FEE = "VENDOR_FEE",
}

export enum serviceInvoiceStatus {
  CANCELLED = "CANCELLED",
  CREATED = "CREATED",
  FAILED = "FAILED",
  PAID = "PAID",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
}

export interface DiscountInput {
  category: DiscountCategory;
  value: number;
}

export interface MerchantAddressInput {
  line1: string;
  line2?: string | null;
  line3?: string | null;
  city: string;
  stateCode: string;
  zip?: string | null;
  addressType?: number | null;
  countryCode: number;
}

export interface MerchantContactInput {
  firstName: string;
  lastName: string;
  ssn: string;
  dateOfBirth: string;
  countryOfCitizenshipCode: number;
  address?: MerchantAddressInput | null;
}

export interface MerchantPhoneInput {
  phoneTypeCodeID: number;
  areaCode: string;
  phoneNumber: string;
  extension?: string | null;
}

export interface TreatmentInput {
  name: string;
  code: string;
  quantity: number;
  value: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
