import React from 'react'
import styled from 'styled-components/macro'

import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Spinner } from 'evergreen-ui'
import { Button, Card, CardHeader, TextInputField, FormError } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {  CLEARENT_BANKS, CLEARENT_UPDATE_BANK } from 'graphql/_clearent-onboarding'

export type Props = {
  merchantSetting: Types.clearentMerchantSetting
  createMerchant: [Types.ClearentCreateMerchantProp, Types.ClearentCreateMerchantStatusProp]
  updateMerchant: [Types.ClearentUpdateMerchantProp, Types.ClearentUpdateMerchantStatusProp]
  updateAddress: [Types.ClearentUpdateAddressProp, Types.ClearentUpdateAddressStatusProp],
  udpateTaxPayer: [Types.ClearentUpdateTaxPayerProp, Types.ClearentUpdateTaxPayerStatusProp],
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const ClearentBank = ({ 
  dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')


  //const [] = useState(false)


  const { loading, error, data } = useQuery<Types.clearentBanks>(CLEARENT_BANKS)

  //const [] = useMutation<Types.clearentAddBank, Types.clearentAddBankVariables>(CLEARENT_ADD_BANK)

  const [updateBank] = useMutation<Types.clearentUpdateBank, Types.clearentUpdateBankVariables>(CLEARENT_UPDATE_BANK )


  const initialValues = {
    
    // BankName: data?.clearentBanks ? data.clearentBanks[0].bankName: '',
    // BankAccountType: data?.clearentBanks ? data.clearentBanks[0].bankAccountTypeID : '',
    // RoutingNumber:  data?.clearentBanks ? data.clearentBanks[0].aba : '',
    // AccountNumber:  data?.clearentBanks ? data.clearentBanks[0].accountNumber : '',
    // NameOnAccount:  data?.clearentBanks ? data.clearentBanks[0].nameOnAccount : '',
    BankName: '',
    BankAccountType:'',
    RoutingNumber:'',
    AccountNumber:'',
    NameOnAccount:''

  }

  return (
    <>
    {loading || error || !data ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : (
      <Formik
        initialValues={initialValues}
        validate={args => {
          if (!dirty && args !== initialValues) setDirty(true)
        }}
        onSubmit={() => {
          showConfirmDialog({
            body: 'Are you sure you want to save this plan?',
            confirm: () => {
              updateBank({
                // variables: {
                //   bankName: args.BankName ?? '',                  
                //   nameOnAccount: args.NameOnAccount ?? '',
                //   bankAccountID: 0 
                // }
              })
            }
          })
        }}
        validationSchema={Yup.object({
          BankName: Yup.string().required('Bank Name is required'),
          BankAccountType: Yup.string().required('Bank Account Type is required'),
          RoutingNumber: Yup.string().required('Routing Number is required'),
          AccountNumber: Yup.string().required('Account Number is required'),
          NameOnAccount: Yup.string().required('Name on Account is required')
        })}
      >
        <Form style={{ height: '100%', width: '100%' }}>
          <SheetBodyLayout>
            <Pane gridArea="body" background="blueTint" overflow="scroll">
              <Card gridArea="offices" backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader withButton>
                  <Heading size={500}>Bank Account Details</Heading>
                  {/* <Button iconBefore={['fas', 'plus']} onClick={() => showBankSheet()}>
                    Add Bank
                  </Button> */}
                </CardHeader>

                {/* <Card elevation={1} hoverElevation={2} >
                  <Heading marginBottom={4}>{bankName}</Heading>
                  <Text>{bankAccountTypeID}</Text>
                  <Text>{aba}</Text>
                  <Text>{accountNumber}</Text>
                  <Text>{nameOnAccount}</Text>
                </Card> */}

                {/* <Card elevation={1} hoverElevation={2} >
                  <Heading marginBottom={4}>Axis Bank</Heading>
                  <Text>Current Account</Text>
                  <Text>654321</Text>
                  <Text>0987654321</Text>
                  <Text>Bpk Tech</Text>
                </Card> */}

                {/* </Card>
          <Card backgroundColor="white" elevation={0} marginX={16} padding={0}> */}

                <Pane display="flex" marginTop={0} marginX={12} >

                  <TextInputField name="BankName" label="Bank Name" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="BankAccountType" label="Bank Account Type" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="RoutingNumber" label="ABA (Routing Number)" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="AccountNumber" label="Account Number" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="NameOnAccount" label="Name on Account" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <FormError marginLeft={20} marginTop={0} max={2} />
                <Pane display="flex" marginTop={0} marginBottom={20} marginX={12} >
                  <Button
                    type="submit"
                    justifyContent="center"
                    appearance="primary"
                    marginRight={8}
                    marginLeft={20}
                  >
                    Save
 </Button>
                  <Button
                    type="button"
                    justifyContent="center"
                  >
                    Cancel
 </Button>
                </Pane>

              </Card>
            </Pane>
          </SheetBodyLayout>
        </Form>
      </Formik>
      )}
    </>
  )
}

export default ClearentBank

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`

