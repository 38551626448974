import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Formik, Form } from 'formik'
import * as Types from 'types'

import {
    Card,
    CardHeader,
    Button,
    Tab,
    useToken,
    TextInput
} from '@pearly/lib'
import { SideSheet, Pane, Text, Heading, Avatar, Tablist, toaster } from 'evergreen-ui'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { UPSERT_ONBOARDING_DASHBOARD } from 'graphql/_onboarding-dashboard'
import { useModal } from 'components/modal-provider'
import moment from 'moment'
import { SET_CURRENT_ACCOUNT_ID } from 'graphql/_header'

export type Props = {
    practiceDashboardInfo: any
    isShown: boolean
    setIsShown: (isShown: boolean) => void
    initialPath?: 'practice'
}

const OboardDashboardSheet = ({ practiceDashboardInfo, isShown, setIsShown }: Props) => {
    const apolloClient = useApolloClient()
    const { refreshToken } = useToken()
    const [setAccount, setAccountStatus] = useMutation<Types.SetCurrentAccountId, Types.SetCurrentAccountIdVariables>(
        SET_CURRENT_ACCOUNT_ID
    )

    useEffect(() => {
        if (setAccountStatus.error) {
            toaster.danger(`Unable to switch accounts`)
        } else if (setAccountStatus.data && !setAccountStatus.loading) {
            refreshToken()
            setTimeout(() => {
                toaster.success(`Switched Practice to ${setAccountStatus.data?.setCurrentAccountId}`)
            }, 500)
        }
    }, [setAccountStatus, refreshToken, apolloClient])

    const showConfirmDialog = useModal('confirm')

    const [upsertOnboarding, upsertStatus] = useMutation<Types.upsertOnboardingDashboard, Types.upsertOnboardingDashboardVariables>
        (UPSERT_ONBOARDING_DASHBOARD);

    useEffect(() => {
        if (upsertStatus.error) toaster.danger(`Unable to update`)
        else if (upsertStatus.data && !upsertStatus.loading) {
            setIsShown(false)
            toaster.success(`Updated successfully!`)
        }
    }, [upsertStatus, practiceDashboardInfo, setIsShown])

    return (
        <>
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
                width={500}
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column'
                }}
                shouldCloseOnOverlayClick={false}
            >

                <SheetLayout>
                    <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
                        <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                            <Pane display="flex" alignItems="center">
                                <Avatar name={""} size={40} />
                                <Pane marginLeft={16}>
                                    <Heading size={600}>
                                    </Heading>
                                    <Text size={400}>Practice Overview</Text>
                                </Pane>
                            </Pane>
                            <Text size={400}>
                                <strong>ID: </strong>
                                {practiceDashboardInfo?.account?.code}
                            </Text>
                        </Pane>
                        <Pane display="flex" padding={8}>
                            <Tablist>
                                <Tab
                                    textTransform="uppercase"
                                    isSelected={true}
                                >
                                    Practice Live
                                </Tab>
                            </Tablist>
                        </Pane>
                    </CardHeader>

                    <Pane gridArea="body" overflow="hidden">
                        <SheetBodyLayout>
                            <Pane gridArea="body" background="blueTint" overflow="scroll">
                                <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Practice Details</Heading>
                                    </CardHeader>
                                    <Pane padding={16}>
                                        <Pane padding={8} display="flex" flexDirection="column">
                                            <Text size={500} marginBottom={8}>
                                                <strong>Plan: </strong>
                                                {practiceDashboardInfo.plan?.name}
                                            </Text>

                                            <Text size={500} marginBottom={8}>
                                                <strong>Start Date: </strong>
                                                {practiceDashboardInfo.subscription?.startDate ? moment.utc(practiceDashboardInfo.subscription.startDate).format('M/D/YYYY') : ''}
                                            </Text>
                                            <Text size={500} marginBottom={8}>
                                                <strong>End Date: </strong>
                                                {practiceDashboardInfo.subscription?.endDate ? moment.utc(practiceDashboardInfo.subscription.endDate).format('M/D/YYYY') : ''}

                                            </Text>
                                            <Text size={500} marginBottom={8}>
                                                <strong>Fee: </strong>
                                                {practiceDashboardInfo.subscription?.fee ?? 0.00}
                                            </Text>
                                            <Text size={500} marginBottom={8}>
                                                <strong>Status: </strong>
                                                {practiceDashboardInfo.plan?.status}
                                                {/* {errorInfo.status} */}
                                            </Text>
                                        </Pane>
                                    </Pane>
                                </Card>

                                <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                                    <CardHeader>
                                        <Heading size={500}>Status Details</Heading>
                                    </CardHeader>
                                    <Formik initialValues={
                                        {
                                            practicefeeanalysis: moment(practiceDashboardInfo.practiceFeeAnalysisComplete).format('YYYY-MM-DD'),
                                            practiceprogram: moment(practiceDashboardInfo.practiceProgramApproved).format('YYYY-MM-DD'),
                                            marketingmaterial: moment(practiceDashboardInfo.marketingMaterialApproved).format('YYYY-MM-DD'),
                                            softwaresetupguide: moment(practiceDashboardInfo.softwareSetupGuideSent).format('YYYY-MM-DD'),
                                            patientPlansComplete: moment(practiceDashboardInfo.patientPlansComplete).format('YYYY-MM-DD'),
                                            practice: moment(practiceDashboardInfo.practiceLive).format('YYYY-MM-DD')
                                        }
                                    } onSubmit={({ practicefeeanalysis, practiceprogram, marketingmaterial, softwaresetupguide, patientPlansComplete, practice }) => {
                                        showConfirmDialog({
                                            body: `Are you sure you want to update?`,
                                            confirm: () => {
                                                //const now = new Date();
                                                upsertOnboarding({
                                                    variables: {
                                                        id: practiceDashboardInfo.id,
                                                        practiceName: practiceDashboardInfo.practiceName,
                                                        practiceFeeAnalysisComplete: moment(new Date(practicefeeanalysis)).isValid() ? new Date(practicefeeanalysis) : null,
                                                        practiceProgramApproved: moment(new Date(practiceprogram)).isValid() ? new Date(practiceprogram) : null,
                                                        marketingMaterialApproved: moment(new Date(marketingmaterial)).isValid() ? new Date(marketingmaterial) : null,
                                                        softwareSetupGuideSent: moment(new Date(softwaresetupguide)).isValid() ? new Date(softwaresetupguide) : null,
                                                        patientPlansComplete: moment(new Date(patientPlansComplete)).isValid() ? new Date(patientPlansComplete) : null,
                                                        // practiceLive: practice ? now : null

                                                    }
                                                })
                                            }
                                        })
                                    }}>
                                        <Form>
                                            <Pane padding={10} marginLeft="auto">
                                                <Pane display="flex">
                                                    <Text size={500}>Practice Fee Analysis</Text>
                                                    <TextInput marginLeft="auto" marginRight={10} height={38} type="date" name="practicefeeanalysis" />
                                                </Pane>
                                                <Pane display="flex" >
                                                    <Text size={500}>Practice Program</Text>
                                                    <TextInput marginLeft="auto" marginRight={10} height={38} type="date" name="practiceprogram" />
                                                </Pane>
                                                <Pane display="flex" >
                                                    <Text size={500}>Marketing Material</Text>
                                                    <TextInput marginLeft="auto" marginRight={10} height={38} type="date" name="marketingmaterial" />
                                                </Pane>
                                                <Pane display="flex" >
                                                    <Text size={500}>Software Setup Guide</Text>
                                                    <TextInput marginLeft="auto" marginRight={10} height={38} type="date" name="softwaresetupguide" />
                                                </Pane>
                                                <Pane display="flex" >
                                                    <Text size={500}>Patient Plans Complete</Text>
                                                    <TextInput marginLeft="auto" marginRight={10} height={38} type="date" name="patientPlansComplete" />
                                                </Pane>
                                                {/* <Pane display="flex" >
                                                    <Checkbox disabled={true} name="practice" label={<Text size={500}>Practice Live</Text>} />
                                                    <TextInput marginLeft="auto" marginRight={10} height={38} type="date" />
                                                </Pane> */}

                                                <br />


                                                <Button marginRight={8}
                                                    type="submit"
                                                    intent="success">
                                                    Save
                                                </Button>

                                            </Pane>
                                        </Form>
                                    </Formik>
                                </Card>
                            </Pane>
                            <Pane
                                gridArea="footer"
                                elevation={0}
                                padding={16}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Button marginLeft={0}
                                    appearance="primary"
                                    justifyContent="center"
                                    height={40}
                                    onClick={() => {
                                        setAccount({ variables: { accountId: practiceDashboardInfo?.accountId } })
                                    }}>
                                    Impersonate
                                </Button>
                            </Pane>

                        </SheetBodyLayout>
                    </Pane>
                </SheetLayout>

            </SideSheet>

        </>
    )
}

export default OboardDashboardSheet

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`